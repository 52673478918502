// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import {
  Label,
  BarChart,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Brush,
  Area,
  Bar,
  PieChart,
  Pie,
  Line,
  LineChart,
  Cell
} from 'recharts'
import {
  Grid,
  Chip,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import React, { Component } from 'react'
import clsx from 'clsx'

// Components.
import AlertInfo from '../Alert/AlertInfo'
import AlertError from '../Alert/AlertError'

// Axios instance.
import axios from '../../../axios'

// Shared.
import { prettyPrintDateHours, printReadableDateTime } from '../../../shared/functions'
import { useStyles } from '../../styles'

// =============================================================================
// Aux. functions.
// =============================================================================

// Bar colors.
const BAR_COLORS = [
  'rgb(120, 29, 66)',
  'rgb(244, 115, 64)',
  'rgb(239, 47, 136)',
  'rgb(136, 67, 242)',
  'rgb(0, 136, 254)',
  'rgb(0, 196, 159)',
  'rgb(255, 187, 40)',
  'rgb(255, 128, 66)'
]

// Default bar color.
const DEFAULT_BAR_COLOR = '#ccc'

// Get bar color.
const getBarColor = key => BAR_COLORS[+key] || DEFAULT_BAR_COLOR

// Lighten a color.
const lightenColor = color => color.replace(')', ', 0.6)')

// Radians.
const RADIAN = Math.PI / 180

// =============================================================================
// Component declaration.
// =============================================================================

class CustomizedAxisTick extends Component {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
}

const dateFormatter = date => {
  return prettyPrintDateHours(new Date(date));
};

// Stateful component declaration.
class Chart extends Component {
  state = {
    loading: false,
    error: null,
    data: null,
    dataFile: null,
    chartAnimationActive: true,
    group: 'none',
    dataFiles: null,
    selection: null
  }

  componentDidMount () {
    document.addEventListener('keydown', this.keyboardHandler, false)
    let data = this.groupDataByDate(this.props.data, this.props.groupData)
    this.setState({ ...this.state, data: data, dataFile: this.props.dataFile, group: this.props.groupData, dataFiles: this.props.dataFiles, selection: this.props.selection }, () => {
      if(this.props.date){
        this.handleChangeDataFile(this.props.date, true)
      }
    })
  }

  componentDidUpdate() {
    if (this.props.groupData !== this.state.group){
      let data = this.groupDataByDate(this.props.data, this.props.groupData)
      this.setState({ ...this.state, data: data, group: this.props.groupData})
    }
  }

  groupJSONSameKey = (mainJSON, secondaryJSON, key, params) => {
    if (mainJSON.hasOwnProperty(key)){
      for (const param of params){
        mainJSON[key][param] += isNaN(secondaryJSON[param]) ? 0 : secondaryJSON[param]
      }
      mainJSON[key]['iterations'] += 1
    } else {
      mainJSON[key] = {}
      for (const param of params){
        mainJSON[key][param] = isNaN(secondaryJSON[param]) ? 0 : secondaryJSON[param] 
      }
      mainJSON[key].label = key
      mainJSON[key]['iterations'] = 0
    }
    return mainJSON
  }

  groupDataByDate = (data, group) => {
    if(!group || group === 'none' || !this.props.time) return data
    let newData = {}
    for(const value of data){
      let time = new Date(value.label.getTime())
      switch (group) {
        case 'hourly':
          time.setMinutes(0,0,0)
          break;
        case 'daily':
          time.setHours(0,0,0,0)
          break;
        case 'weekly':
          time.setHours(0,0,0,0)
          const day = time.getDay()
          const diff = day === 0 ? 6 : day - 1
          time.setDate(time.getDate() - diff)
          break;
        case 'monthly':
          time.setHours(0,0,0,0)
          time.setDate(1)
          break;
        case 'yearly':
          time.setHours(0,0,0,0)
          time.setMonth(0,1)
          break;
        default:
          return
      }
      let params = ['processed', 'executions', 'failed', 'skipped']
      this.groupJSONSameKey(newData, value, time, params)
    }

    let newDataArray = []
    for(let group in newData){
      delete newData[group].iterations
      // newData[group].label = newData[group].label.getTime()
      newDataArray.push(newData[group])
    }
    console.log(newDataArray)
    return newDataArray
  }

  keyboardHandler = e => {
    if (e.keyCode === 82) { // Pressed R
        console.log(`R key pressed : reloading graph`)
        // e.stopPropagation()
        // console.log(`Current datafile : ${this.state.dataFile}`)
        this.props.onRefresh()
    }
        // } else if ((e.keyCode === 38 || e.keyCode === 39) && e.ctrlKey) {
    //   if (this.props.dataFiles && this.props.dataFile && this.props.dataFiles.includes(this.props.dataFile)){
    //     let indexOfDataFile = this.props.dataFiles.indexOf(this.props.dataFile)
    //     if (e.keyCode === 38 && indexOfDataFile < this.props.dataFiles.length){

    //     } else if (e.keyCode === 39 && indexOfDataFile > 0) {
          
    //     }
    //   }
  }
  
  handleChangeDataFile = (dataFile, date = false) => {
    const query = new URLSearchParams(this.props.location.search);
    let fileNames = []
    for(let fileObject of this.state.dataFiles) {
      fileNames.push(fileObject.file)
      if(date){
        if (fileObject.date.split(' ')[0] === dataFile){
          dataFile = fileObject.file
          if (fileObject.data){
            query.set('date', fileObject.date.split(' ')[0])
            this.props.history.push({
              pathname: this.props.location.pathname,
              search: query.toString()
            })
            this.setState({ ...this.state, data: fileObject.data, dataFile})
            return
          }
        }
        this.setState({error: {status: 404, message:'Error found on query "date".', details:[`Invalid date (${dataFile}) for service (${this.state.selection})`]}})
        query.delete('date')
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: query.toString()
        })
        return
      } else {
        if (fileObject.file === dataFile && fileObject.data){
          query.set('date', fileObject.date.split(' ')[0])
          this.props.history.push({
            pathname: this.props.location.pathname,
            search: query.toString()
          })
          this.setState({ ...this.state, data: fileObject.data, dataFile})
          return
        }
      } 
    }

    this.setState({ ...this.state, loading: true, error: null, data: null })
    const indexDataFile = fileNames.indexOf(dataFile)
    this.getDataFiles(this.state.dataFiles, indexDataFile)
      .then(dataFiles => {
        this.setState({ ...this.state, loading: false, data: this.state.dataFiles[indexDataFile].data, dataFiles: dataFiles, dataFile})
      })
  }

  async getDataFiles(dataFiles, indexDataFile){
    let filesToGet = []
    for(let i = 0; i < dataFiles.length; i++){
      if(i > indexDataFile -2 && i < indexDataFile + 2 && !dataFiles[i].data){
       filesToGet.push([i, dataFiles[i].file]) 
      }
    }
    const axiosGetter = filesToGet.map(fileToGet => axios.get(`/api/data/${fileToGet[1]}`))
    const finalData = await Promise.all(axiosGetter)
    for (let file of filesToGet){
      let fileIndex = file[0]
      dataFiles[fileIndex].data = finalData.shift().data
    }
    return dataFiles
  }

  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  render () {
    if (this.state.loading) {
      return <LinearProgress />
    } else if (this.state.error) {
      return <AlertError>{this.state.error}</AlertError>
    } else if (!this.state.data || !this.state.data.length) {
      return <AlertInfo>No data found for this section.</AlertInfo>
    }

    // Get the X axis values.
    const xAxisValues = _.keys(_.head(this.state.data)).filter(k => k !== 'label')

    // Init the width and height.
    const height = this.props.height || 400
    const width = this.props.width || height

    // Build chart.
    let chart = null
    switch (this.props.type) {
      case 'bar':
        chart = (
          <ResponsiveContainer width='100%' height={height}>
            <BarChart data={this.state.data} margin={{ left: 24 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='label' />
              <YAxis>
                <Label position='left' angle={270} style={{ textAnchor: 'middle' }}>
                  Total
                </Label>
              </YAxis>
              <Tooltip />
              <Brush dataKey='label' height={18} stroke={getBarColor(0)} />
              <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
              {
                xAxisValues.map((keyName, key) => (
                  <Bar
                    key={key}
                    stackId={this.props.stacked ? 'a' : null}
                    dataKey={keyName}
                    fill={getBarColor(key)}
                    name={keyName}
                    isAnimationActive={this.state.chartAnimationActive}
                  />
                ))
              }
            </BarChart>
          </ResponsiveContainer>
        )
        break
      case 'line':
        chart = (
          <ResponsiveContainer width='100%' height={height}>
            <AreaChart data={this.state.data} margin={{ left: 24 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='label'
                height={70}
                tick={this.props.time ? true : <CustomizedAxisTick />}
                scale={this.props.timeScale ? "time" : ""}
                type="date"
                tickFormatter={(tick) => this.props.time ? dateFormatter(tick) : ''}
              />
              <YAxis>
                <Label position='left' angle={270} style={{ textAnchor: 'middle' }}>
                  Total
                </Label>
              </YAxis>
              <Tooltip />
              <Brush dataKey='label' height={18} stroke={getBarColor(0)} />
              <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
              {
                xAxisValues.map((keyName, key) => (
                  <Area
                    key={key}
                    type='monotone'
                    dataKey={keyName}
                    stroke={getBarColor(key)}
                    fill={lightenColor(getBarColor(key))}
                    name={keyName}
                    isAnimationActive={this.state.chartAnimationActive}
                  />
                ))
              }
            </AreaChart>
          </ResponsiveContainer>
        )
        break
      case 'pie':
        const dataKey = Object.keys(this.state.data[0]).find(k => k !== 'label')
        const outerRadius = 0.4 * height
        chart = (
          <ResponsiveContainer width='100%' height={height}>
            <PieChart width={width} height={height}>
              <Tooltip />
              <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
              <Pie
                data={this.state.data}
                dataKey={dataKey}
                nameKey='label'
                label={this.renderCustomizedLabel}
                labelLine={false}
                cx="50%"
                cy="50%"
                outerRadius={outerRadius}
                fill={DEFAULT_BAR_COLOR}
                animationBegin={80}
                isAnimationActive={this.state.chartAnimationActive}
              >
                {
                  this.state.data.map((entry, index) => {
                    const color = entry.color || getBarColor(index)
                    return (
                      <Cell key={`cell-${index}`} fill={color} />
                    )
                  })
                }
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )
        break
      case 'biaxial':
        chart = (
          <ResponsiveContainer width='100%' height={height}>
            <LineChart data={this.state.data} margin={{ left: 24, right: 24}}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey="label" />
              <YAxis yAxisId="left">
                <Label yAxisId='left' value={`${this.props.left_dataset_label ? this.props.left_dataset_label : xAxisValues[0]}`} position='left' angle={270} style={{ textAnchor: 'middle' }}/>
              </YAxis>
              <YAxis yAxisId="right" orientation="right">
                <Label yAxisId='right' value={`${this.props.right_dataset_label ? this.props.right_dataset_label : xAxisValues[1]}`} position='right' angle={90} style={{ textAnchor: 'middle' }}/>
              </YAxis>
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey={this.props.left_dataset_label ? this.props.left_dataset_label : xAxisValues[0]}  stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line yAxisId="right" type="monotone" dataKey={this.props.right_dataset_label ? this.props.right_dataset_label : xAxisValues[1]}  stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        )
        break
      case 'twocharts':
        chart = (
          <>
          <ResponsiveContainer width='100%' height={height}>
            <LineChart data={this.state.data} margin={{ left: 24, right: 24}}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey="label" />
              <YAxis yAxisId="left">
                <Label yAxisId='left' value={`${xAxisValues[0]}`} position='left' angle={270} style={{ textAnchor: 'middle' }}/>
              </YAxis>
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey={xAxisValues[0]}  stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
          <ResponsiveContainer width='100%' height={height}>
            <LineChart data={this.state.data} margin={{ left: 24, right: 24}}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey="label" />
              <YAxis yAxisId="left">
                <Label yAxisId='left' value={`${xAxisValues[1]}`} position='left' angle={270} style={{ textAnchor: 'middle' }}/>
              </YAxis>
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey={xAxisValues[1]}  stroke="#82ca9d" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </>
        )
        break
      default:
    }

    let dataFileSelection = null
    if (this.props.timestamped && this.props.dataFiles) {
      dataFileSelection = (
        <div className={this.props.classes.mt2}>
          <FormControl className={this.props.classes.formControl}>
            <InputLabel id="report-timestamp-label">Date</InputLabel>
            <Select
              labelId="report-timestamp-label"
              id="report-timestamp"
              value={this.state.dataFile}
              onChange={e => this.handleChangeDataFile(e.target.value)}
            >
              {
                this.props.dataFiles.map((element, key) => (
                  <MenuItem key={key} value={element.file}>{printReadableDateTime(element.date)}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      )
    }
    let serviceSelection = null
    if (this.props.selector){
      serviceSelection = (
        <div className={clsx(this.props.classes.mt2, this.props.classes.center)}>
        <FormControl className={this.props.classes.formControl}>
          <InputLabel id="report-timestamp-label">Service selector</InputLabel>
          <Select
            labelId="report-timestamp-label"
            id="report-timestamp"
            value={this.state.selection}
            onChange={e => this.props.handleChangeSelector(e.target.value)}
          >
            {
              this.props.selector.map((element, key) => (
                <MenuItem key={key} value={element}>{element}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
      )
    }

    return (
      <Grid container className={this.props.classes.mb3}>
        <Grid item xs={12}>
          {serviceSelection}
          {chart}
          <div className={clsx(this.props.classes.mt3, this.props.classes.center)}>
            {this.props.dataFile ? <Chip variant='outlined' color='default' label={this.state.dataFile} /> : null}
            {dataFileSelection}
          </div>
        </Grid>
      </Grid>
    )
  }
}

// Style component.
const ChartStyled = props => {
  const classes = useStyles()
  return <Chart classes={classes} {...props} />
}

// Export.
export default ChartStyled
