// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  MenuItem,
  Menu,
  Link,
  Button
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

// Styles.
import { useStyles } from '../../../styles'

// Shared.
import { beautifySectionName } from '../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class TopMenuSection extends Component {
  state = {
    myAccountAnchorEl: null
  }

  handleMenuSectionClick = (e) => {
    this.setState({
      myAccountAnchorEl: e.currentTarget
    })
  }

  handleMenuSectionClose = () => {
    this.setState({
      myAccountAnchorEl: null
    })
  }

  onSubMenuItemClick = subsectionName => {
    this.handleMenuSectionClose()
    if (this.props.subsections[subsectionName].type === 'section'){
      this.props.loadSection(subsectionName, this.props.sectionName)
    } else {
      this.props.loadAlertDetails(subsectionName)
    }
  }

  render () {
    if (this.props.subsections) {
      return (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleMenuSectionClick}
            className={this.props.section && this.props.section.parentKey === this.props.sectionName ? this.props.classes.active : null}
          >
            {beautifySectionName(this.props.sectionName)} <ArrowDropDownIcon />
          </Button>
          <Menu
            id='menu-appbar'
            className='TopMenuSection'
            anchorEl={this.state.myAccountAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={!!this.state.myAccountAnchorEl}
            onClose={this.handleMenuSectionClose}
          >
            { this.props.sectionName === 'alerts' ? <Link key='alerts' component={RouterLink} to={`/section/alerts`} 
              className='TopMenuSubsection'>
                <MenuItem>
                  <div className={this.props.section && this.props.section.parentKey === this.props.sectionName ? this.props.classes.active : null}>All alerts</div>
                </MenuItem>
            </Link> : null }
            {
              Object.keys(this.props.subsections).map((subsectionName, key) => {
                return (
                  <Link key={key} component={RouterLink} to={`/section/${this.props.sectionName}/${subsectionName}`} onClick={() => this.onSubMenuItemClick(subsectionName)} className='TopMenuSubsection'>
                    <MenuItem>
                      <div className={this.props.section && this.props.section.parentKey === this.props.sectionName && this.props.section.sectionKey === subsectionName ? this.props.classes.active : null}>{this.props.subsections[subsectionName].title}</div>
                    </MenuItem>
                  </Link>
                )
              })
            }
          </Menu>
        </>
      )
    } else {
      return (
        <Link component={RouterLink} to={`/section/${this.props.sectionName}`}>
          <Button aria-controls="simple-menu" aria-haspopup="true" className={this.props.section && this.props.section.sectionKey === this.props.sectionName ? this.props.classes.active : null}>
            {beautifySectionName(this.props.sectionName)}
          </Button>
        </Link>
      )
    }
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    section: state.section.section
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSection: (sectionName, sectionParent, serviceName = undefined) => dispatch(actions.loadSection(sectionName, sectionParent, serviceName)),
    loadAlertDetails: alertName => dispatch(actions.loadAlertDetails(alertName))
  }
}

// Style component.
const TopMenuSectionStyled = props => {
  const classes = useStyles()
  return <TopMenuSection classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(TopMenuSectionStyled)
