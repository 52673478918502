// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import axios from 'axios'

// =============================================================================
// Instance.
// =============================================================================


console.log (`process.env: ${JSON.stringify(process.env,null,2)}`)
const devMode = process.env.NODE_ENV === 'development'
const axios_conf = devMode ? {baseURL: process.env.REACT_APP_API_URL} : {}

// Return axios instance.
const axiosInstance = axios.create(axios_conf);

// Export.
export default axiosInstance
