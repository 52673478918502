// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Modal, Box, Typography, Slider } from '@material-ui/core'
import axios from '../../../axios'

// =============================================================================
// Component declaration.
// =============================================================================

class DashboardModal extends Component {

    state={
        threshold: {},
        open: false,
        value: 10
    }

    componentDidMount() {
        this.setState({threshold: {...this.props.threshold}})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.threshold !== this.props.threshold){
            this.setState({
                threshold: {...this.props.threshold}
            })
        }
    }

    onChangeHandle = (value, propertyName) => {
        let updatedThreshold = this.state.threshold
        updatedThreshold[propertyName] = value
        this.setState({threshold: updatedThreshold})
    }

    saveThreshold() {
        const api_path = `/api/thresholds/${this.props.sectionName}`    
        let threshold = this.state.threshold
        axios.post(api_path, {
            threshold
        })
        .then(this.props.onClose)
        .catch(error => {
            this.props.onClose()
        })
    }

    deleteThreshold = () => {
        const api_path = `/api/thresholds/${this.props.sectionName}`    
        let threshold = this.state.threshold
        axios.delete(api_path, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                threshold: threshold
            }
        })
        .then(() => {
            this.handleCloseDialog()
            this.props.loadAlertDetails(this.props.sectionName)
        })
        .catch(error => {
            this.handleCloseDialog()
        })
    }

    handleOpenDialog = () => {
        this.setState({open: true})
    }

    handleCloseDialog = () => {
        this.setState({open: false})
        this.props.onClose()
    }

    handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
          this.setState({value: newValue});
        }
      };

    render () {
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Edit endpoint {this.props.endpoint}
                    </Typography>
                    <Box sx={{ width: 250 }}>
                    <Typography id="non-linear-slider" gutterBottom>
                        Throughput: {(this.state.value)}
                    </Typography>
                    <Slider
                        value={this.state.value}
                        min={this.props.minThreshold}
                        step={1}
                        max={this.props.maxThreshold}
                        // scale={calculateValue}
                        // getAriaValueText={valueLabelFormat}
                        // valueLabelFormat={valueLabelFormat}
                        onChange={this.handleChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                    />
                    </Box>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Texto adicional.
                    </Typography>
                </Box>
            </Modal>
        )
    }
}

export default DashboardModal