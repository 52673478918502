// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
    LinearProgress,
    Box,
    Typography
} from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const LinearProgressWithLabel = props => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box>
  </Box>
)

// Export.
export default LinearProgressWithLabel
