// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import clsx from 'clsx'
import {
  AppBar,
  Toolbar,
  Link,
  Button,
  Badge,
  IconButton,
  Popover,
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

//Icons
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components.
import TopMenuSection from './TopMenuSection/TopMenuSection'
// import TopMenuAlerts from './TopMenuSection/TopMenuAlerts'

// Styles.
import { useStyles } from '../../styles'
import './TopMenu.css'

// Redux actions.
import * as actions from '../../../store/actions'

// Shared.
import { KEY_ARROW_LEFT, KEY_ARROW_RIGHT } from '../../../shared/constants'
import axios from '../../../axios'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
        anchorEl: null,
    };
  }

  componentDidMount () {
    document.addEventListener('keyup', this.handleArrowNavigation)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleArrowNavigation)
  }

  handleArrowNavigation = e => {
    // Do nothing if no section is loaded yet.
    if (this.props.location.pathname.split('/')[2] !== 'alerts' && (!this.props.sectionsArray || !this.props.section)) return
    // Find key of the current section.
    let currentSectionIdx = this.props.location.pathname.split('/')[2] !== 'alerts' && this.props.sectionsArray.findIndex(s => s.key === this.props.section.sectionKey && s.parent === this.props.section.parentKey)
    if (!currentSectionIdx || currentSectionIdx < 0){
      currentSectionIdx = this.props.sectionsArray.findIndex(s => s.key === this.props.match.params.sectionName)
      if (currentSectionIdx < 0) return
    }
    // Listen to the arrow key press event.
    if (!e.ctrlKey) return
    switch(e.keyCode) {
      case KEY_ARROW_LEFT:
        if (currentSectionIdx === 0) return
        this.setRedirectSection(this.props.sectionsArray[currentSectionIdx - 1])
        break
      case KEY_ARROW_RIGHT:
        if (currentSectionIdx >= this.props.sectionsArray.length - 1) return
        this.setRedirectSection(this.props.sectionsArray[currentSectionIdx + 1])
        break
      default:
    }
  }

  setRedirectSection (section) {
    const redirectTo = `/section/${section.parent ? `${section.parent}/` : ''}${section.key}`
    console.log(`REDIRECTING TO ${redirectTo}`)
    if(section.parent === 'alerts'){
      this.props.loadAlertDetails(section.key)
    } else {
      this.props.loadSection(section.key, section.parent)
    }
    this.props.history.push(redirectTo)
  }

  handleOpen = (event) => {
      this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
      this.setState({ anchorEl: null });
  };

  checkRead = async () => {
    const api_path='/api/events/reset'
    axios.post(api_path).then(
      this.props.loadUnreadEvents(),
      this.handleClose()
    )
  };

  showNotifications = () => {
    console.log("Redirect")
  }

  redirectToSection = (href) => {
    window.location.href=`/section/${href}`
  }

  
  render () {
    return (
      <AppBar position='absolute' className={clsx(this.props.classes.appBar, this.props.sideMenuOpen && this.props.classes.appBarShift)}>
        <Toolbar className={this.props.classes.toolbar}>
          <Link component={RouterLink} to='/'>
            <img alt='SBMTester' src='/logo.md.png' className={this.props.classes.navAvatar} />
          </Link>
          <div className='MenuSections'>
            {
              Object.keys(this.props.sections).map((sectionName, key) => {
                return (
                  <TopMenuSection
                    key={key}
                    sectionName={sectionName}
                    subsections={this.props.sections[sectionName] && this.props.sections[sectionName].dataFile ? null : this.props.sections[sectionName]}
                  />
                )
              })
            }
            {/* <Link component={RouterLink} to='/admin'>
              <Button aria-controls="simple-menu" aria-haspopup="true" className={window.location.pathname === '/admin' ? this.props.classes.active : null}>
                Admin
              </Button>
            </Link> */}
            <Link component={RouterLink} to='/dashboard'>
              <Button aria-controls="simple-menu" aria-haspopup="true" className={window.location.pathname === '/dashboard' ? this.props.classes.active : null}>
                Dashboard
              </Button>
            </Link>
          </div>
          <div className='NotificationsMenu'>
          {this.props.unreadEvents ? (
            <div>
            <IconButton color="inherit" onClick={this.handleOpen}>
                  <Badge badgeContent={this.props.unreadEvents.length > 5 ? '+5' : this.props.unreadEvents.length} color="error">
                      <NotificationsIcon />
                  </Badge>
              </IconButton>

              <Popover
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                  <List 
                    disablePadding style={{ width: 300, overflow: "auto" }} 
                    subheader={<><ListSubheader divider>Notifications</ListSubheader><Divider/></>}
                  >
                      {this.props.unreadEvents.length > 0 ? (
                          this.props.unreadEvents.map((notif, index) => (
                              <ListItem key={index} divider button onClick={() => window.location.href=`/section/${notif.href}`}>
                                <ListItemIcon style={{paddingLeft: '3%'}}>
                                  {notif.type === 'error' ? <ReportIcon color="error"/> : notif.type === 'warning' ? <WarningIcon color="warning"/> : <InfoIcon color="primary"/>}
                                </ListItemIcon>
                                <ListItemText primary={`${notif.message}`} secondary={
                                  <>
                                    <Typography component="span" variant="body2" display='block' color="textSecondary">
                                      {notif.origin}
                                    </Typography>
                                    <Typography component="span" variant="caption" display='block' color="textSecondary">
                                      {moment(notif.date).format("DD/MM/YYYY HH:mm:ss")}
                                    </Typography>
                                  </>
                                }/>
                              </ListItem>
                          ))
                      ) : (
                          <Typography style={{fontStyle: 'italic', paddingLeft: '5%', paddingTop:'5%'}}>You have no notifications</Typography>
                      )}
                  </List>
                  <div style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                      <Button variant="contained" size="small" onClick={this.checkRead}>
                          <MarkEmailReadIcon  style={{ fontSize: "1.3em", marginTop:'-0.15em', marginRight: '0.5em' }}/>Mark as read
                      </Button>
                      <Button variant="contained" size="small" onClick={this.showNotifications}>
                          <VisibilityIcon style={{ fontSize: "1.3em", marginTop:'-0.15em', marginRight: '0.5em' }}/> Show all
                      </Button>
                  </div>
              </Popover>
              </div>) : (
                <NotificationsIcon fontSize="large" />
              )}
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    sections: state.sections.sections,
    sectionsArray: state.sections.sectionsArray,
    section: state.section.section,
    alert: state.alerts,
    alerts: state.alerts.alerts,
    unreadEvents: state.events.unreadEvents
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSection: (sectionName, sectionParent) => dispatch(actions.loadSection(sectionName, sectionParent)),
    loadAlertDetails: sectionName => dispatch(actions.loadAlertDetails(sectionName)),
    loadUnreadEvents: () => dispatch(actions.loadUnreadEvents())
  }
}

// Style component.
const TopMenuStyled = props => {
  const classes = useStyles()
  return <TopMenu classes={classes} {...props} />
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopMenuStyled))
