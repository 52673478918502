// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from "react";
import {
  LinearProgress,
  TextField,
  Grid,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { List, ListItem, MenuItem } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

// Components.
import AlertError from "../../../UI/Alert/AlertError";
import SectionTitle from "../Section/SectionTitle";

// Style.
import { useStyles } from "../../../styles";

// Redux actions.
import * as actions from "../../../../store/actions";

// Shared.
import { getErrorMessage } from "../../../../shared/functions";

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class AdminView extends Component {
  state = {
    editable: {},
  };

  handleClickEditable = (thresholdKey) => {
    this.setState((prevState) => ({
      editable: {
        ...prevState.editable,
        [thresholdKey]: !prevState.editable[thresholdKey],
      },
    }));
  };

  onChangeHandle = (value, alertKey, propertyKey, propertyName) => {
    let newAdminConfig = this.props.adminConfig;
    newAdminConfig.thresholds[alertKey][propertyKey][propertyName] = value;
    this.setState((prevState) => ({
      ...prevState,
      adminConfig: newAdminConfig,
    }));
  };

  addThreshold = (alertKey) => {
    let newAdminConfig = this.props.adminConfig;
    newAdminConfig.thresholds[alertKey].push({
      name:'',
      type: 'max',
      warnLevel:0,
      alertLevel: 0,
      link: ''
    });
    this.setState((prevState) => ({
      ...prevState,
      adminConfig: newAdminConfig,
    }));
  };

  deleteThreshold = (alertKey, thresholdKey) => {
    let newAdminConfig = this.props.adminConfig;
    newAdminConfig.thresholds[alertKey].splice(thresholdKey, 1);
    this.setState((prevState) => ({
      ...prevState,
      adminConfig: newAdminConfig,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateAdminConfig(this.props.adminConfig)
  };

  componentDidMount() {
    this.props.resetSection();
    this.props.loadAdminConfig();
  }

  render() {
    let content = null;
    if (this.props.loading) {
      content = <LinearProgress />;
    } else if (this.props.error) {
      content = <AlertError>{getErrorMessage(this.props.error)}</AlertError>;
    } else if (this.props.adminConfig) {
      if (this.props.adminConfig.thresholds) {
        let thresholdFields = [];
        for (let alertKey in this.props.adminConfig.thresholds) {
          let alertThresholds = this.props.adminConfig.thresholds[alertKey];
          let alertFields = [];
          for (let thresholdKey in alertThresholds) {
            // let threshold = alertThresholds[thresholdKey];
            let threshold = this.props.adminConfig.thresholds[alertKey][thresholdKey]
            alertFields.push(
              <ListItem>
                <TextField
                  fullWidth
                  type="text"
                  label="Name"
                  value={
                    this.props.adminConfig.thresholds[alertKey][thresholdKey].name ? this.props.adminConfig.thresholds[alertKey][thresholdKey].name : ""
                  }
                  onChange={(event) =>
                    this.onChangeHandle(event.target.value, alertKey, thresholdKey, "name")
                  }
                />
                <TextField
                  fullWidth
                  select
                  label="Type"
                  value={threshold.type ? threshold.type : "max"}
                  onChange={(event) =>
                    this.onChangeHandle(event.target.value, alertKey, thresholdKey, "type")
                  }
                >
                  <MenuItem value="max">Max</MenuItem>
                  <MenuItem value="min">Min</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Warn level"
                  type="text"
                  value={threshold.warnLevel ? threshold.warnLevel : 0}
                  onChange={(event) =>
                    this.onChangeHandle(parseInt(event.target.value), alertKey, thresholdKey, "warnLevel")
                  }
                />
                <TextField
                  fullWidth
                  label="Alert level"
                  type="text"
                  value={threshold.alertLevel ? threshold.alertLevel : 0}
                  onChange={(event) =>
                    this.onChangeHandle(parseInt(event.target.value), alertKey, thresholdKey, "alertLevel")
                  }
                />
                <TextField
                  fullWidth
                  label="Link"
                  type="text"
                  value={threshold.link ? threshold.link : ""}
                  onChange={(event) =>
                    this.onChangeHandle(event.target.value, alertKey, thresholdKey, "link")
                  }
                />
                <Button>
                  <DeleteIcon
                    onClick={() => {
                      this.deleteThreshold(alertKey, thresholdKey);
                    }}
                  />
                </Button>
              </ListItem>
            );
          }
          thresholdFields.push(
            <Grid>
              <ListItem key={alertKey}>
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h6">{alertKey}</Typography>
                </Box>
                <Button
                  onClick={() => {
                    this.handleClickEditable(alertKey);
                  }}
                >
                  {this.state.editable[alertKey] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Button>
              </ListItem>
              <Collapse in={this.state.editable[alertKey]} timeout="auto">
                <List style={{ paddingLeft: "2em" }}>
                  {alertFields}
                  <Button
                    fullWidth
                    display="center"
                    onClick={() => {
                      this.addThreshold(alertKey);
                    }}
                  >
                    <AddIcon />
                  </Button>
                </List>
              </Collapse>
            </Grid>
          );
        }
        content = thresholdFields;
      }
    }
    return (
      <Grid>
        <SectionTitle onRefresh={() => this.props.loadAdminConfig()}>
          Alert Thresholds
        </SectionTitle>
        <Box component="form" onSubmit={this.handleSubmit}>
          {content}
          <Button type="submit">Save</Button>
        </Box>
      </Grid>
    );
  }
}

// State mapping.
const mapStateToProps = (state) => {
  return {
    loading: state.section.loading,
    error: state.section.error,
    adminConfig: state.adminConfig.adminConfig,
  };
};

// Action mapping.
const mapDispatchToProps = (dispatch) => {
  return {
    // Action mapping.
    resetSection: () => dispatch(actions.resetSection()),
    loadAdminConfig: () => dispatch(actions.loadAdminConfig()),
    updateAdminConfig: (adminConfig) => dispatch(actions.updateAdminConfig(adminConfig)),
  };
};

// Style component.
const AdminStyled = (props) => {
  const classes = useStyles();
  return <AdminView classes={classes} {...props} />;
};

// Export.
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminStyled)
);
