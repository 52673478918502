// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load event start.
const loadUnreadEventsStart = () => {
  return {
    type: actions.LOAD_UNREAD_EVENTS_START
  }
}

// Load event success.
const loadUnreadEventsSuccess = unreadEvents => {
  return {
    type: actions.LOAD_UNREAD_EVENTS_SUCCESS,
    unreadEvents
  }
}

// Load event fail.
const loadUnreadEventsFailed = error => {
  return {
    type: actions.LOAD_UNREAD_EVENTS_FAILED,
    error
  }
}

// Load event.
export const loadUnreadEvents = () => {
  
  return dispatch => {
    dispatch(loadUnreadEventsStart())
    // Perform request.
    const api_path = `/api/events/unread`
    console.log(`GET to ${api_path}`)
    axios.get(api_path)
      .then(response => {
        dispatch(loadUnreadEventsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadUnreadEventsFailed(getErrorMessage(error)))
      })
  }
}
