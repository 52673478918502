// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { IconButton } from '@material-ui/core'
import ModeEditIcon from '@mui/icons-material/ModeEdit';

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ConfigButton = props => {
  return (
    <IconButton aria-label='refresh' onClick={props.onClick}>
      <ModeEditIcon />
    </IconButton>
  )
}

// Export.
export default ConfigButton


