// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Container,
  Button,
  Box
} from '@material-ui/core'

// Components.
import Copyright from '../../UI/Brand/Copyright'
import ErrorBox from '../../ErrorPage/Error404/ErrorBox/ErrorBox'
import Breadcrumb from '../../UI/Brand/Breadcrumb'

// Sections.
import Home from './Home/Home'
import Section from './Section/Section'
import AlertsSummary from './Alerts/AlertsSummary'
import AlertDetails from './Alerts/AlertDetails'
import AlertProcess from './Alerts/AlertProcess'
import AdminView from './Admin/AdminView'
import PatDashboard from './Patloader/PatDashboard'

// Style.
import { useStyles } from '../../styles'

// Shared.
import { beautifySectionName } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Content = props => {
  const classes = useStyles()
 
   // Test notification
  const reqPermission = () => {
  
    Notification.requestPermission()
  }
  
  const notifyMe = (bodyStr) => {
    console.log(`Button clicked, creating notification`)
    // notify('Welcome to the documentation', 'info')
    const notification = new Notification("Process", { body: bodyStr})
    playSound('/lightsaber.mp3')
    
    notification.onclick = (event) => {
      // event.preventDefault(); // prevent the browser from focusing the Notification's tab
      console.log(`Clicked!!`)
      
    }
   
   }

  const playSound = (url) => {
      const audio = new Audio(url);
      audio.play();
    }

  /* Test Play sound + notificatin
  setTimeout(() => {
    console.log("Delayed for 5 seconds.");
    notifyMe(`Hey!`);
  }, 5000);
  */

    
  // Find the section to load.
  let content = null
  let path = []
  
  console.log(`Loading section: ${JSON.stringify(props.section,null,2)}`)
  console.log(`Content props reqParams: ${JSON.stringify(props.reqParams,null,2)}`)

  switch (props.section) {
    case 'home':
      content = <Home {...props} />
      break
    case 'sectionWithoutParent':
      path = [{ label: beautifySectionName(props.reqParams.sectionName) }]
      content = <Section name={props.reqParams.sectionName} parent={null} />
      break
    case 'sectionWithParent':
      path = [{ label: beautifySectionName(props.reqParams.sectionParent) }, { label: beautifySectionName(props.reqParams.sectionName) }]
      content = <Section name={props.reqParams.sectionName} parent={props.reqParams.sectionParent} />
      break
    case 'alerts':
      path = [{ label: 'Alerts' }]
      content = <AlertsSummary />
      break
    case 'alertDetails':
      path = [{ label: 'Alerts', link: '/section/alerts' }, { label: props.reqParams.sectionName }]
      content = <AlertDetails sectionName={props.reqParams.sectionName} />
      break
    case 'alertProcess':
      path = [{ label: 'Alerts', link: '/section/alerts' }, { label: props.reqParams.sectionName, link: `/section/alerts/${props.reqParams.sectionName}`}, {label: props.reqParams.process}]
      content = <AlertProcess sectionName={props.reqParams.sectionName} process={props.reqParams.process} type='process'/>
      break
    case 'admin':
      path = [{ label: 'Admin'}]
      content = <AdminView/>
      break
    case 'dashboard':
      content = <PatDashboard />
      break
    default:
      content = <ErrorBox {...props} />
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth='xl' className={classes.container}>
        <Box pt={1} pb={2}>
          <Breadcrumb elements={path} />
        </Box>
        {content}
        <Button onClick={reqPermission}> permission</Button>
        <Button onClick={notifyMe}> Notify </Button>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  )
}

// Export.
export default Content
