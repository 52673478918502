// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load sections start.
const loadSectionsStart = () => {
  return {
    type: actions.LOAD_SECTIONS_START
  }
}

// Load sections success.
const loadSectionsSuccess = sections => {
  return {
    type: actions.LOAD_SECTIONS_SUCCESS,
    sections
  }
}

// Load sections fail.
const loadSectionsFailed = error => {
  return {
    type: actions.LOAD_SECTIONS_FAILED,
    error
  }
}

// Load sections.
export const loadSections = () => {
  
  return dispatch => {
    dispatch(loadSectionsStart())
    // Perform request.
    console.log(`GET to /sections`)
    axios.get(`/api/sections`)
      .then(response => {
        dispatch(loadSectionsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadSectionsFailed(getErrorMessage(error)))
      })
  }
}
