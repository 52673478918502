// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load dashboard start.
const loadDashboardStart = () => {
  return {
    type: actions.LOAD_DASHBOARD_START
  }
}

// Load dashboard success.
const loadDashboardSuccess = dashboard => {
  return {
    type: actions.LOAD_DASHBOARD_SUCCESS,
    dashboard
  }
}

// Load dashboard fail.
const loadDashboardFailed = error => {
  return {
    type: actions.LOAD_DASHBOARD_FAILED,
    error
  }
}

// Load dashboard.
export const loadDashboard = () => {
  
  return dispatch => {
    dispatch(loadDashboardStart())
    // Perform request.
    const api_path = `/api/dashboard`
    console.log(`GET to ${api_path}`)
    axios.get(api_path)
      .then(response => {
        dispatch(loadDashboardSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadDashboardFailed(getErrorMessage(error)))
      })
  }
}

// Load dashboard without reloading.
export const loadDashboardNoReload = () => {
  return dispatch => {
    // Perform request.
    const api_path = `/api/dashboard`
    console.log(`GET to ${api_path}`)
    axios.get(api_path)
      .then(response => {
        dispatch(loadDashboardSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadDashboardFailed(getErrorMessage(error)))
      })
  }
}