// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { IconButton } from '@material-ui/core'
import CachedIcon from '@material-ui/icons/Cached'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const RefreshButton = props => {
  return (
    <IconButton aria-label='refresh' onClick={props.onClick}>
      <CachedIcon />
    </IconButton>
  )
}

// Export.
export default RefreshButton
