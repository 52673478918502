// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import ReactDOM from 'react-dom'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'

// Styles.
import './index.css'

// Components.
import App from './app/App'

// Reducers.
import sectionReducer from './store/reducers/section'
import sectionsReducer from './store/reducers/sections'
import alertsReducer from './store/reducers/alerts'
import adminConfigReducer from './store/reducers/adminConfig'
import dashboardReducer from './store/reducers/dashboard'
import eventsReducer from './store/reducers/events'
// =============================================================================
// Main.
// =============================================================================

// Compose Redux enhancers.
const middlewareEnhancer = applyMiddleware(thunk)
const composedEnhancers = compose(middlewareEnhancer)

// Combine reducers.
const rootReducer = combineReducers({
  section: sectionReducer,
  sections: sectionsReducer,
  alerts: alertsReducer,
  adminConfig: adminConfigReducer,
  dashboard: dashboardReducer,
  events: eventsReducer
})

// Create store.
const store = createStore(rootReducer, composedEnhancers)

// Init the app component.
const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

// Render the application.
ReactDOM.render(app, document.getElementById('root'))
