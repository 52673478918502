// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  alerts: null,
  alertDetails: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading alerts.
const loadAlertsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    alerts: null
  })
}

// Load a alerts successfully.
const loadAlertsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    alerts: action.alerts
  })
}

// Load alerts, with error.
const loadAlertsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    alerts: null
  })
}

// Start loading alert details.
const loadAlertDetailsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    alertDetails: null
  })
}

// Load a alert details successfully.
const loadAlertDetailsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    alertDetails: action.alertDetails
  })
}

// Load alert details, with error.
const loadAlertDetailsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    alertDetails: null
  })
}


// Start loading alert process.
const loadAlertProcessStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    alertProcess: null
  })
}

// Load a alert processes successfully.
const loadAlertProcessSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    alertProcess: action.alertProcess
  })
}

// Load alert process, with error.
const loadAlertProcessFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    alertProcess: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_ALERTS_START:
      return loadAlertsStart(state, action)
    case actions.LOAD_ALERTS_SUCCESS:
      return loadAlertsSuccess(state, action)
    case actions.LOAD_ALERTS_FAILED:
      return loadAlertsFailed(state, action)
    case actions.LOAD_ALERT_DETAILS_START:
      return loadAlertDetailsStart(state, action)
    case actions.LOAD_ALERT_DETAILS_SUCCESS:
      return loadAlertDetailsSuccess(state, action)
    case actions.LOAD_ALERT_DETAILS_FAILED:
      return loadAlertDetailsFailed(state, action)
    case actions.LOAD_ALERT_PROCESS_START:
      return loadAlertProcessStart(state, action)
    case actions.LOAD_ALERT_PROCESS_SUCCESS:
      return loadAlertProcessSuccess(state, action)
    case actions.LOAD_ALERT_PROCESS_FAILED:
      return loadAlertProcessFailed(state, action)
    default:
      return state
  }
}

export default reducer
