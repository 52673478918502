// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip, Tooltip } from '@material-ui/core'
import { prettyPrintMs } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const NumberOfStuff = props => {
  const number = props.children || 0

  const style = {}
  if (props.marginLeft) style.marginLeft = 8
  if (props.condensed) style.padding = '0 !important'

  let color = 'primary'
  let variant = 'default'
  if (props.maxValue && +props.maxValue <= +number) {
    color = 'secondary'
  } else if (props.minValue && +props.minValue >= +number) {
    variant = 'outlined'
    color = 'default'
  }

  const chip = (
    <Chip
      variant={variant}
      color={color}
      size='small'
      label={props.type === 'time' ? prettyPrintMs(number) : +number}
      style={style}
    />
  )

  if (props.maxValue) {
    return (
      <Tooltip title={`Max. value: ${props.maxValue}`} arrow placement='top'>
        {chip}
      </Tooltip>
    )
  } else if (props.minValue) {
    return (
      <Tooltip title={`Min. value: ${props.minValue}`} arrow placement='top'>
        {chip}
      </Tooltip>
    )
  }
  return chip
}

// Export.
export default NumberOfStuff
