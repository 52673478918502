// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import Title from '../../../UI/Brand/Title'
import ConfigButton from '../../../UI/Button/ConfigButton'
import RefreshButton from '../../../UI/Button/RefreshButton'
import EyeButton from '../../../UI/Button/EyeButton'

// Style.
import './SectionTitle.css'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const SectionTitle = props => {
  return (
    <div className='SectionTitle'>
      <Title>{props.children}</Title>
      <div>
        {props.edit && <ConfigButton onClick={props.edit}/>}
        {props.handleShowInvocationsZero && <EyeButton showInvocationZero={props.showInvocationZero} onClick={props.handleShowInvocationsZero}/>}
        <RefreshButton onClick={props.onRefresh} />
      </div>
    </div>
  )
}

// Export.
export default SectionTitle
