export const LOAD_SECTIONS_START = 'LOAD_SECTIONS_START'
export const LOAD_SECTIONS_FAILED = 'LOAD_SECTIONS_FAILED'
export const LOAD_SECTIONS_SUCCESS = 'LOAD_SECTIONS_SUCCESS'
export const LOAD_SECTION_START = 'LOAD_SECTION_START'
export const LOAD_SECTION_FAILED = 'LOAD_SECTION_FAILED'
export const LOAD_SECTION_SUCCESS = 'LOAD_SECTION_SUCCESS'
export const RESET_SECTION = 'RESET_SECTION'
export const LOAD_ALERTS_START = 'LOAD_ALERTS_START'
export const LOAD_ALERTS_FAILED = 'LOAD_ALERTS_FAILED'
export const LOAD_ALERTS_SUCCESS = 'LOAD_ALERTS_SUCCESS'
export const LOAD_ALERT_DETAILS_START = 'LOAD_ALERT_DETAILS_START'
export const LOAD_ALERT_DETAILS_FAILED = 'LOAD_ALERT_DETAILS_FAILED'
export const LOAD_ALERT_DETAILS_SUCCESS = 'LOAD_ALERT_DETAILS_SUCCESS'
export const LOAD_ALERT_PROCESS_START = 'LOAD_ALERT_PROCESS_START'
export const LOAD_ALERT_PROCESS_FAILED = 'LOAD_ALERT_PROCESS_FAILED'
export const LOAD_ALERT_PROCESS_SUCCESS = 'LOAD_ALERT_PROCESS_SUCCESS'
export const LOAD_ADMIN_CONFIG_START = 'LOAD_ADMIN_CONFIG_START'
export const LOAD_ADMIN_CONFIG_FAILED = 'LOAD_ADMIN_CONFIG_FAILED'
export const LOAD_ADMIN_CONFIG_SUCCESS = 'LOAD_ADMIN_CONFIG_SUCCESS'
export const UPDATE_ADMIN_CONFIG_START = 'UPDATE_ADMIN_CONFIG_START'
export const UPDATE_ADMIN_CONFIG_FAILED = 'UPDATE_ADMIN_CONFIG_FAILED'
export const UPDATE_ADMIN_CONFIG_SUCCESS = 'UPDATE_ADMIN_CONFIG_SUCCESS'
export const LOAD_DASHBOARD_START = 'LOAD_DASHBOARD_START'
export const LOAD_DASHBOARD_FAILED = 'LOAD_DASHBOARD_FAILED'
export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS'
export const LOAD_UNREAD_EVENTS_START = 'LOAD_UNREAD_EVENTS_START'
export const LOAD_UNREAD_EVENTS_FAILED = 'LOAD_UNREAD_EVENTS_FAILED'
export const LOAD_UNREAD_EVENTS_SUCCESS = 'LOAD_UNREAD_EVENTS_SUCCESS'