// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { useState } from 'react'
import {
    TableRow,
    Box,
    TableCell,
    IconButton,
    TableHead,
    TableBody,
    Table
} from '@material-ui/core'

// Components.
import SettingsButton from '../Button/SettingsButton'
import DashboardModal from '../Modals/DashobardModal'
import CircularProgressWithLabel from '../Progress/CircularProgressWithLabel'
import LinearProgressWithLabel from '../Progress/LinearProgressWithLabel'
import StatusIndicator from '../Progress/StatusIndicator'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.

const TestCaseRow = React.memo(( { name, row, refs, toggleRefs } ) => {
    const [open, setOpen] = useState(false)
    // const open = (name && refs[name] && refs[name]['open']) || false
    const openModal = (name && refs[name] && refs[name]['openModal']) || false

    const startTime = row.startTime ? new Date(row.startTime * 1000).toLocaleString("es-ES",  { dateStyle: "short", timeStyle: "short" }) : "-"
    const endTime = row.endTime ? new Date(row.endTime * 1000).toLocaleString("es-ES",  { dateStyle: "short", timeStyle: "short" }) : "-"
    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{background:'#ADD8E640'}}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => 
                setOpen(!open)
                // toggleRefs(name, 'open')
              }
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {name}
          </TableCell>
          <TableCell align="right">
            <StatusIndicator status={row.status === "Finished" ? "OK" : "Running..."} />
          </TableCell>
          <TableCell align="right">{startTime}</TableCell>
          <TableCell align="right">{endTime}</TableCell>
          {row.hasOwnProperty("endpoints") && (
            <>
              <TableCell><LinearProgressWithLabel value={
                Object.values(row.endpoints).reduce((total, endpoint) => {
                  let messagesSended = row.status === "Finished" ? endpoint.data.nMessages : parseInt(endpoint.messagesSended) || 0
                  let progress = messagesSended / parseInt(endpoint.data.nMessages) * 100
                  return total + progress
                }, 0)/Object.keys(row.endpoints).length
                }/></TableCell>
              <TableCell align="right"><CircularProgressWithLabel total={Object.keys(row.endpoints).length} current={Object.keys(row.endpoints).length}/></TableCell>
              <TableCell align="right">{
              Object.values(row.endpoints).reduce((max,endpoint) => Math.max(max, endpoint.data.duration), -Infinity)
              } s</TableCell>
          </>
          )}
        </TableRow>
        { open && <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8} >
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: 'none' }} />
                    <TableCell style={{ fontWeight: "bold" }}>Endpoint</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align='right'>Num. Messages</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align='right'>Progress</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align='right'>Threads</TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align='right'>Duration</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.hasOwnProperty("endpoints") && Object.keys(row.endpoints).map(key => {
                    let endpoint = row.endpoints[key]
                    let messagesSended = row.status === "Finished" ? endpoint.data.nMessages : parseInt(endpoint.messagesSended) || 0
                    let progress = messagesSended / parseInt(endpoint.data.nMessages) * 100
                  // row.endpoints && row.endpoints.map((endpoint) => {
                    return (
                    <TableRow key={key}>
                      <TableCell style={{ border: 'none' }} />
                      <TableCell component="th" scope="row">
                        {key}
                      </TableCell>
                      <TableCell align='right'>
                        <StatusIndicator status={progress === 100 ? "OK" : "Running..."}/>
                      </TableCell>
                      <TableCell align='right'>
                        {messagesSended}/{endpoint.data.nMessages}
                      </TableCell>
                      <TableCell align='right'>
                        <LinearProgressWithLabel value={progress}/>
                      </TableCell>
                      <TableCell align='right'>
                        {endpoint.data.threads}
                      </TableCell>
                      <TableCell align='right'>
                        {endpoint.data.duration} s
                      </TableCell>
                      <TableCell align='right'>
                          <SettingsButton onClick={() => toggleRefs(name, 'openModal')}/>
                          <DashboardModal 
                            open={openModal}
                            onClose={() => toggleRefs(name, 'openModal')}
                            endpoint={endpoint.data.nMessages}
                            minThreshold={10}
                            maxThreshold={30}
                          />
                      </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
        </TableRow>}
      </>
    );
})

// Export
export default TestCaseRow