// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Link,
  Chip,
  Paper,
  Container,
  LinearProgress
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

// Components.
import Chart from '../../../UI/Chart/Chart'
import AlertError from '../../../UI/Alert/AlertError'
import SectionTitle from '../Section/SectionTitle'
import AlertInfo from '../../../UI/Alert/AlertInfo'

// Style.
import { useStyles } from '../../../styles'
import './AlertsSection.css'

// Redux actions.
import * as actions from '../../../../store/actions'

// Shared.
import { getErrorMessage, isAlertErrored, isAlertSignificant, isAlertLevel, isWarningLevel, prettyPrintDateTime, beautifySectionName } from '../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Section extends Component {
  componentDidMount () {
    this.props.loadAlerts()
  }

  getKpiHealthData = alertProps => {
    let nErroredKpis = 0
    let nStableKpis = 0
    let nIgnoredKpis = 0
    for (let i = 0; i < alertProps.alerts.length; i++) {
      const alert = alertProps.alerts[i]
      if (isAlertErrored({ ...alert, ...alertProps })) {
        nErroredKpis++
      } else if (isAlertSignificant(alert)) {
        nStableKpis++
      } else {
        nIgnoredKpis++
      }
    }
    return [
      { label: 'Ignored', total: nIgnoredKpis, color: '#aaa' },
      { label: 'Errored', total: nErroredKpis, color: '#ee5056' },
      { label: 'Stable', total: nStableKpis, color: '#4caf50' }
    ]
  }

  getLevelHealthData = alertProps => {
    let nAlerts = 0
    let nStable = 0
    let nWarning = 0
    for (let i = 0; i < alertProps.alerts.length; i++) {
      const level = alertProps.alerts[i]
      // console.log(`Checking level: ${JSON.stringify(level,null,2)}`) // debug
      if (isAlertLevel(level)) {
        nAlerts++
      } else if (isWarningLevel(level)) {
        nWarning++
      } else {
        nStable++
      }
    }
    return [
      { label: 'Alert', total: nAlerts, color: '#ee5056' },
      { label: 'Warning', total: nWarning, color: '#ff8c00' },
      { label: 'Stable', total: nStable, color: '#4caf50' }
    ]
  }

  getProcessHealthData = alertProps => {
    let nIgnoredProcess = 0
    let nAlertsProcess = 0
    let nStableProcess = 0
    let nWarningProcess = 0
    processLoop:
    for (let i = 0; i < alertProps.alerts.length; i++) {
      const level = alertProps.alerts[i]
      if (! level.hasOwnProperty('thresholds') || level['thresholds'].length === 0){
        nIgnoredProcess++
        continue
      }
      const thresholds = level.thresholds
      let warnProcess = false
      for (let j = 0; j < thresholds.length; j++){
        if (level.hasOwnProperty(thresholds[j].name)){
          if (thresholds[j].type === 'max'){
          if (level[thresholds[j].name] > thresholds[j].alertLevel){
            nAlertsProcess++
            continue processLoop
          } else if (level[thresholds[j].name] > thresholds[j].warnLevel){
            warnProcess = true
          }
          } else if (thresholds[j].type === 'min'){
            if (level[thresholds[j].name] < thresholds[j].alertLevel){
              nAlertsProcess++
              continue processLoop
            } else if (level[thresholds[j].name] < thresholds[j].warnLevel){
              warnProcess = true
            }
          }
        }
      } 
      if (warnProcess){
        nWarningProcess++
      } else {
        nStableProcess++
      }
    }
    return [
      { label: 'Ignored', total: nIgnoredProcess, color: '#aaa' },
      { label: 'Alert', total: nAlertsProcess, color: '#ee5056' },
      { label: 'Warning', total: nWarningProcess, color: '#ff8c00' },
      { label: 'Stable', total: nStableProcess, color: '#4caf50' }
    ]
  }

  render () {
    let content = null
    if (this.props.loading) {
      content = <LinearProgress />
    } else if (this.props.error) {
      content = <AlertError>{getErrorMessage(this.props.error)}</AlertError>
    } else if (!this.props.alerts || typeof this.props.alerts !== 'object' || !Object.keys(this.props.alerts).length) {
      return <AlertInfo>No alerts found.</AlertInfo>
    } else if (this.props.alerts) {
      content = (
        <Grid container spacing={3} className='AlertSummary'>
          {
            Object.keys(this.props.alerts).map((alertsSectionName, alertsSectionKey) => { 
              const alertsSection = this.props.alerts[alertsSectionName]

              const data = alertsSection.alert_type === 'counts' ? this.getLevelHealthData(alertsSection) : alertsSection.type === 'processes' ? this.getProcessHealthData(alertsSection) : this.getKpiHealthData(alertsSection)
              
              return (
                <Grid item xs={12} md={6} lg={4} key={alertsSectionKey}>
                  <Paper elevation={2} className="AlertsSectionTypes">
                    <h2>{beautifySectionName(alertsSection.title)}</h2>
                    <Grid container className='AlertsSectionTypesBody'>
                      <Grid item xs={12} className='AlertsSectionType'>
                        <Link component={RouterLink} color='inherit' to={`/section/alerts/${alertsSectionName}`}>
                          <h3>Date: {prettyPrintDateTime(alertsSection.date)} <Chip size='small' label={`${alertsSection.alerts.length} levels`} /></h3>
                          <Chart
                            type='pie'
                            data={data}
                            height={400}
                            dataFile={alertsSection.fileName ? alertsSection.fileName : 'File not specified'}
                          />
                        </Link>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )
            })
          }
        </Grid>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Section'>
              <SectionTitle
                onRefresh={() => this.props.loadAlerts()}
              >
                Alerts
              </SectionTitle>
              <Container maxWidth='xl' className={this.props.classes.cardGrid}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {content}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    alerts: state.alerts.alerts,
    loading: state.alerts.loading,
    error: state.alerts.error
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadAlerts: () => dispatch(actions.loadAlerts())
  }
}

// Style component.
const SectionStyled = props => {
  const classes = useStyles()
  return <Section classes={classes} {...props} />
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionStyled))
