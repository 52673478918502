// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const EyeButton = props => {
    return (
    <Tooltip title={ props.showInvocationZero ? "Hide 0 invocations" : "Show 0 invocations" }>
        <IconButton aria-label='refresh' onClick={props.onClick}>
            { props.showInvocationZero ? <VisibilityOffIcon /> : <VisibilityIcon /> }
        </IconButton>
    </Tooltip>
  )
}

// Export.
export default EyeButton
