// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  dashboard: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading dashboard.
const loadDashboardStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    dashboard: null
  })
}

// Load a dashboard successfully.
const loadDashboardSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    dashboard: action.dashboard
  })
}

// Load dashboard, with error.
const loadDashboardFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    dashboard: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_DASHBOARD_START:
      return loadDashboardStart(state, action)
    case actions.LOAD_DASHBOARD_SUCCESS:
      return loadDashboardSuccess(state, action)
    case actions.LOAD_DASHBOARD_FAILED:
      return loadDashboardFailed(state, action)
    default:
      return state
  }
}

export default reducer
