// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  sections: [],
  sectionsArray: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading sections.
const loadSectionsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    sections: null
  })
}

// Load a sections successfully.
const loadSectionsSuccess = (state, action) => {
  // Build array of section keys.
  const sectionsArray = []
  for (let key in action.sections) {
    if (Object.prototype.hasOwnProperty.call(action.sections, key)) {
      const sectionObj = action.sections[key]
      if (sectionObj.dataFile) {
        sectionsArray.push({ key: sectionObj.sectionKey, parent: sectionObj.parentKey })
      } else {
        for (let sKey in sectionObj) {
          if (Object.prototype.hasOwnProperty.call(sectionObj, sKey)) {
            const subsectionObj = sectionObj[sKey]
            sectionsArray.push({ key: subsectionObj.sectionKey, parent: subsectionObj.parentKey })
          }
        }
      }
    }
  }
  return updateObject(state, {
    loading: false,
    error: null,
    sections: action.sections,
    sectionsArray
  })
}

// Load sections, with error.
const loadSectionsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    sections: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SECTIONS_START:
      return loadSectionsStart(state, action)
    case actions.LOAD_SECTIONS_SUCCESS:
      return loadSectionsSuccess(state, action)
    case actions.LOAD_SECTIONS_FAILED:
      return loadSectionsFailed(state, action)
    default:
      return state
  }
}

export default reducer
