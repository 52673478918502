// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  unreadEvents: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading event.
const loadUnreadEventsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    unreadEvents: null
  })
}

// Load a event successfully.
const loadUnreadEventsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    unreadEvents: action.unreadEvents
  })
}

// Load event, with error.
const loadUnreadEventsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    unreadEvents: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_UNREAD_EVENTS_START:
      return loadUnreadEventsStart(state, action)
    case actions.LOAD_UNREAD_EVENTS_SUCCESS:
      return loadUnreadEventsSuccess(state, action)
    case actions.LOAD_UNREAD_EVENTS_FAILED:
      return loadUnreadEventsFailed(state, action)
    default:
      return state
  }
}

export default reducer
