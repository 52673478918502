// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load alerts start.
const loadAlertsStart = () => {
  return {
    type: actions.LOAD_ALERTS_START
  }
}

// Load alerts success.
const loadAlertsSuccess = alerts => {
  return {
    type: actions.LOAD_ALERTS_SUCCESS,
    alerts
  }
}

// Load alerts fail.
const loadAlertsFailed = error => {
  return {
    type: actions.LOAD_ALERTS_FAILED,
    error
  }
}

// Load alerts.
export const loadAlerts = () => {
  return dispatch => {
    dispatch(loadAlertsStart())
    // Perform request.
    axios.get(`/api/alerts`)
      .then(response => {
        dispatch(loadAlertsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadAlertsFailed(getErrorMessage(error)))
      })
  }
}

// Load alert details start.
const loadAlertDetailsStart = () => {
  return {
    type: actions.LOAD_ALERT_DETAILS_START
  }
}

// Load alert details success.
const loadAlertDetailsSuccess = alertDetails => {
  return {
    type: actions.LOAD_ALERT_DETAILS_SUCCESS,
    alertDetails
  }
}

// Load alert details fail.
const loadAlertDetailsFailed = error => {
  return {
    type: actions.LOAD_ALERT_DETAILS_FAILED,
    error
  }
}

// Load alert details.
export const loadAlertDetails = alertsSection => {
  return dispatch => {
    dispatch(loadAlertDetailsStart())
    // Perform request.
    console.log(`GET to /alerts/${alertsSection}`)
    axios.get(`/api/alerts/${alertsSection}`)
      .then(response => {
        dispatch(loadAlertDetailsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadAlertDetailsFailed(getErrorMessage(error)))
      })
  }
}

// Load alert Process start.
const loadAlertProcessStart = () => {
  return {
    type: actions.LOAD_ALERT_PROCESS_START
  }
}

// Load alert Process success.
const loadAlertProcessSuccess = alertProcess => {
  return {
    type: actions.LOAD_ALERT_PROCESS_SUCCESS,
    alertProcess
  }
}

// Load alert Process fail.
const loadAlertProcessFailed = error => {
  return {
    type: actions.LOAD_ALERT_PROCESS_FAILED,
    error
  }
}

// Load alert Process.
export const loadAlertProcess = (alertsSection, processName, limit_process='', date='', maxDate='') => {
  return dispatch => {
    dispatch(loadAlertProcessStart())
    // Perform request.
    axios.get(`/api/alerts/${alertsSection}/${processName}/?limit_process=${limit_process}&date=${date}&maxDate=${maxDate}`)
      .then(response => {
        dispatch(loadAlertProcessSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadAlertProcessFailed(getErrorMessage(error)))
      })
  }
}