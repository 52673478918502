// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load admin start.
const loadAdminConfigStart = () => {
  return {
    type: actions.LOAD_ADMIN_CONFIG_START
  }
}

// Load admin success.
const loadAdminConfigSuccess = adminConfig => {
  return {
    type: actions.LOAD_ADMIN_CONFIG_SUCCESS,
    adminConfig
  }
}

// Load admin fail.
const loadAdminConfigFailed = error => {
  return {
    type: actions.LOAD_ADMIN_CONFIG_FAILED,
    error
  }
}

// Load admin start.
const updateAdminConfigStart = () => {
  return {
    type: actions.UPDATE_ADMIN_CONFIG_START
  }
}

// Load admin success.
const updateAdminConfigSuccess = adminConfig => {
  return {
    type: actions.UPDATE_ADMIN_CONFIG_SUCCESS,
    adminConfig
  }
}

// Load admin fail.
const updateAdminConfigFailed = error => {
  return {
    type: actions.UPDATE_ADMIN_CONFIG_FAILED,
    error
  }
}



export const updateAdminConfig = adminConfig => {
  return dispatch => {
    dispatch(updateAdminConfigStart())
    // Perform request
    const api_path = `/api/admin`
    console.log(`POST to ${api_path}`)
    console.log(adminConfig)
    axios.post(api_path, {
      adminConfig
    })
    .then(response => {
      dispatch(updateAdminConfigSuccess(response.data))
    })
    .catch(error => {
      dispatch(updateAdminConfigFailed(error))
    })
  }
}

// Load admin.
export const loadAdminConfig = () => {
  
  return dispatch => {
    dispatch(loadAdminConfigStart())
    // Perform request.
    const api_path = `/api/admin`
    console.log(`GET to ${api_path}`)
    axios.get(api_path)
      .then(response => {
        dispatch(loadAdminConfigSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadAdminConfigFailed(getErrorMessage(error)))
      })
  }
}
