// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
    Box,
    Typography
} from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const StatusIndicator = ({ status }) => {
    const getStatusStyle = () => {
      switch (status) {
        case "OK":
          return {
            background: "radial-gradient(circle, #00ff00 60%, #004d00 100%)",
            boxShadow: "0 0 2px 0.5px #00ff00",
          };
        case "ERROR":
          return {
            background: "radial-gradient(circle, #ff0000 60%, #800000 100%)",
            boxShadow: "0 0 2px 0.5px #ff0000",
          };
        case "Running...":
          return {
            background: "radial-gradient(circle, #ffaa00 60%, #804c00 100%)",
            boxShadow: "0 0 2px 0.5px #ffaa00",
          };
        default:
          return {
            background: "radial-gradient(circle, #cccccc 60%, #666666 100%)",
            boxShadow: "0 0 2px 0.5px #cccccc",
          };
      }
    };
  
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Box
          style={{
            width: 10,
            height: 10,
            borderRadius: "50%",
            position: "relative",
            top: "-2px", 
            ...getStatusStyle(),
          }}
        />
        <Typography style={{paddingLeft:'0.5em'}}>{status}</Typography>
      </Box>
    );
};

// Export.
export default StatusIndicator