// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Button,
    LinearProgress,
    IconButton,
    Tooltip,
    TextField,
    Paper,
} from '@material-ui/core'

import { CalendarToday, Reorder } from '@material-ui/icons'

// Shared.
import { prettyPrintDate } from '../../../shared/functions'

import '../Chart/ChartFilter.css'

// =============================================================================
// Component declaration.
// =============================================================================


// Stateful component declaration.
class ChartFilter extends Component {

    state= {
        limit: null,
        date: null,
        maxDate: null,
        loading: true,
        showModal: false
    }

    componentDidMount () {
        this.setState({limit: this.props.limit, date: this.props.date, maxDate: this.props.maxDate, loading:false})
    }

    handleChangeLimit = (event) => {
        this.setState({limit:event.target.value})
    }
  
    handleChangeDate = (event) => {
        this.setState({date:event.target.value})
    }

    handleChangeMaxDate = (event) => {
        this.setState({maxDate:event.target.value})
    }

    handleSearchClick = () => {
        this.props.handleSearch(this.state.limit, this.state.date, this.state.maxDate)
    }

    handleScaleClick = (timeScale) => {
        this.props.handleScale(timeScale)
    }

    handleShowModal = (showModal) => {
        this.setState({showModal:!showModal})
    }

    handleGroupChange = (group) => {
        this.props.handleGroupData(group)
    }

    groupType = ['none', 'hourly', 'daily', 'weekly', 'monthly', 'yearly']

    render() {
        if (this.state.loading) {
            return <LinearProgress />
        } else {
            let today = new Date()
            return (
                <>
                <div className='relative'>
                    <Button variant='outlined' style={{backgroundColor:this.state.showModal && '#F8F8FF', textTransform:'none'}} onClick={() => this.handleShowModal(this.state.showModal)}>Add filters...</Button>
                    <div style={{position: 'absolute', textTransform: 'none', left:'35%'}}>
                        {this.groupType.map((group, index) => {
                            return <><Button onClick={() => this.handleGroupChange(group)} className={`textTransformNone ${this.props.groupData === group ? 'active' : 'inactive'}`}> {group} </Button>{this.groupType.length !== index + 1 && '/'}</>
                        })}
                       </div>
                    <div style={{position:'absolute', right:0}}>
                        <Tooltip title="timed axis">
                            <IconButton onClick={() => this.handleScaleClick(true)}><CalendarToday/></IconButton>
                        </Tooltip>
                        <Tooltip title="normalized axis">
                            <IconButton onClick={() => this.handleScaleClick(false)}><Reorder/></IconButton>
                        </Tooltip>
                    </div>
                </div>
                {this.state.showModal && (
                    <div style={{paddingTop:'1.5em', display:'flex'}}>
                    <Paper style={{backgroundColor:'#F8F8FF' ,padding:'1em', display:'flex', justifyContent:'center', width:'auto', alignItems:'center', gap:'3em'}}>
                        <FormControl style={{minWidth: 200}}>
                            <InputLabel shrink={!!this.state.limit}>Number of samples</InputLabel>
                            <Select value={this.state.limit} onChange={this.handleChangeLimit}>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                            </FormControl>
                            <FormControl style={{minWidth: 200}}>
                            <InputLabel>Last ...</InputLabel>
                            <Select value={this.state.date} onChange={this.handleChangeDate}>
                                <MenuItem value={''}>unspecified</MenuItem>
                                <MenuItem value={'1d'}>1 day</MenuItem>
                                <MenuItem value={'7d'}>7 days</MenuItem>
                                <MenuItem value={'1m'}>1 month</MenuItem>
                                <MenuItem value={'1y'}>1 year</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            shrink={!!this.state.maxDate}
                            value={this.state.maxDate ? this.state.maxDate : prettyPrintDate(today)}
                            label="Specific date"
                            type="date"
                            onChange={(newValue) => this.handleChangeMaxDate(newValue)}
                        />
                        <Button variant='outlined' onClick={this.handleSearchClick}>Search</Button>
                        {/* <Button variant='outlined' onClick={this.handleShowModal}>Cancel</Button> */}
                    </Paper>
                    </div>
                )}
                </>
            )
        }
    }
}


// Export.
export default ChartFilter