// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load section start.
const loadSectionStart = () => {
  return {
    type: actions.LOAD_SECTION_START
  }
}

// Load section success.
const loadSectionSuccess = section => {
  return {
    type: actions.LOAD_SECTION_SUCCESS,
    section
  }
}

// Load section fail.
const loadSectionFailed = error => {
  return {
    type: actions.LOAD_SECTION_FAILED,
    error
  }
}

// Load section.
export const loadSection = (sectionName, sectionParent, serviceName = undefined) => {
  
  return dispatch => {
    dispatch(loadSectionStart())
    // Perform request.
    const api_path = `/api/${sectionParent ? `${sectionParent}/${sectionName}` : `${sectionName}`}${(serviceName && `?service=${serviceName}`) || ''}`
    console.log(`GET to ${api_path}`)
    axios.get(api_path)
      .then(response => {
        dispatch(loadSectionSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadSectionFailed(getErrorMessage(error)))
      })
  }
}

// Reset the loaded section.
export const resetSection = () => {
  return {
    type: actions.RESET_SECTION
  }
}
