// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  section: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading section.
const loadSectionsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    section: null
  })
}

// Load a section successfully.
const loadSectionsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    section: action.section
  })
}

// Load section, with error.
const loadSectionsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    section: null
  })
}

// Reset the section.
const resetSection = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    section: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SECTION_START:
      return loadSectionsStart(state, action)
    case actions.LOAD_SECTION_SUCCESS:
      return loadSectionsSuccess(state, action)
    case actions.LOAD_SECTION_FAILED:
      return loadSectionsFailed(state, action)
    case actions.RESET_SECTION:
      return resetSection(state, action)
    default:
      return state
  }
}

export default reducer
