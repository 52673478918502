// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import {
  Link,
  Typography
} from '@material-ui/core'

// Components.
import Version from './Version'

// =============================================================================
// Components.
// =============================================================================

// Stateless component.
const Copyright = () =>
  <Typography variant='body2' color='textSecondary' align='center' component='div'>
    <p style={{ marginBottom: 8 }}>
      <Version />
    </p>
    <p style={{ marginTop: 0 }}>
      <Link color='inherit' href='/'>
        SBMTester
      </Link>{' '}
      {new Date().getFullYear()}
    </p>
  </Typography>

// Export.
export default Copyright
