// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Button,
  Container,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

// Components.
import AlertError from '../../../UI/Alert/AlertError'
import SectionTitle from '../Section/SectionTitle'
import AlertInfo from '../../../UI/Alert/AlertInfo'
import NumberOfStuff from '../../../UI/Chip/NumberOfStuff'
// import BooleanField from '../../../UI/Chip/BooleanField'
import TableColumnTitle from '../../../UI/Table/TableColumnTitle'
import TableFilters from '../../../UI/Table/TableFilters'

// Style.
import { useStyles } from '../../../styles'
import './AlertsSection.css'

// Redux actions.
import * as actions from '../../../../store/actions'

// Shared.
import {
  applyFilter,
  getErrorMessage,
  isAlertLevel,
  isWarningLevel,
  isAlertErrored,
  removeDuplicates,
  calculateNumPages,
  isAlertSignificant,
  prettyPrintDateTime,
  isValidLink,
  prettyPrintMs
} from '../../../../shared/functions'
import {
  RESULTS_PER_PAGE
} from '../../../../shared/constants'
import ConfigButton from '../../../UI/Button/ConfigButton'
import ThresholdModal from '../../../UI/Modals/ThresholdModal'

// =============================================================================
// Component declaration.
// =============================================================================

// Colours
const alert_c = '#f2c9cb' // Red
const warn_c = '#f6d8ac' // Orange
const ignore_c = '#eee' // Gray
const ok_c = '#b6edbf' // Blue
            

// Table columns.
const tableColumns_inv_avg = [
  { name: 'service_name', label: 'Service name', sortable: true },
  { name: 'inv', label: 'Invocations', sortable: true },
  { name: 'avg', label: 'Average', sortable: true },
  { name: 'text', label: 'Description', sortable: false },
  // { name: 'pending', label: 'Pending', sortable: true },
]

const tableColumns_count = [
  { name: 'service_name', label: 'Level', sortable: true },
  { name: 'level_count', label: 'Count', sortable: true },
  { name: 'text', label: 'Description', sortable: false },
]

const tableColumns_process = [
  { name: 'service_name', label: 'Process name', sortable: true },
  { name: 'start_time', label: 'Start time', sortable: true, type: 'timestamp' },
  { name: 'end_time', label: 'End time', sortable: true, type: 'timestamp'},
  { name: 'load_time_ms', label: 'Load time', sortable: true, type: 'time'},
  { name: 'loop_time_ms', label: 'Loop time', sortable: true, type: 'time'},
  { name: 'duration_ms', label: 'Duration', sortable: true, type: 'time'},
  { name: 'processed', label: 'Processed', sortable: true},
  { name: 'executions', label: 'Executions', sortable: true},
  { name: 'skipped', label: 'Skipped', sortable: true},
  { name: 'failed', label: 'Failed', sortable: true},
  // { name: 'last_element', label: 'Last Element', sortable: true}  
]

let tableColumns = []

// Stateful component declaration.
class Section extends Component {
  state = {
    orderBy: 'ID',
    orderDirection: 'desc',
    page: 1,
    showModal: false,
    editThreshold:{},
    editable: false,
    showInvocationZero: true,
    filter: {
      service_name: {
        touched: false,
        operand: 'equals',
        value: null,
        key: 'service_name',
        label: 'Service name',
        type: 'select',
        options: []
      }
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.keyboardHandler, false)
    this.props.loadAlertDetails(this.props.sectionName)
  }
  keyboardHandler = e => {
    if (e.keyCode === 69) { // Pressed R
      this.handleEditable()
    }
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  handleShowModal = (threshold) => {
    this.setState({
      ...this.state,
      showModal: true,
      editThreshold: threshold
    })
  }

  handleCloseModal = () => {
    this.setState({
      ...this.state,
      showModal: false,
      editThreshold: {}
    })
  }

  handleEditable = () => {
    this.setState({
      ...this.state,
      editable: !this.state.editable
    })
  }

  handleShowInvocationsZero = () => {
    this.setState({
      ...this.state,
      showInvocationZero: !this.state.showInvocationZero
    })
  }

  render () {
    let content = null
    if (this.props.loading) {
      content = <LinearProgress />
    } else if (this.props.error) {
      content = <AlertError>{getErrorMessage(this.props.error)}</AlertError>
    } else if (!this.props.alertDetails || !this.props.alertDetails.alerts || !this.props.alertDetails.alerts.length) {
      return <AlertInfo>No alerts found for section "{this.props.sectionName}".</AlertInfo>
    } else if (this.props.alertDetails.alerts && this.props.alertDetails.alerts.length) {
      // Apply filter.
      let alertDetails = applyFilter(this.props.alertDetails.alerts, this.state.filter, this.state.orderBy, this.state.orderDirection)

      // Pagination limits.
      const minLimit = (this.state.page - 1) * RESULTS_PER_PAGE
      const maxLimit = Math.min(alertDetails.length, this.state.page * RESULTS_PER_PAGE)
  
      // Build array of paginated details.
      let alertDetailsPaginated = []
      // console.log(`details: ${JSON.stringify(this.props.alertDetails,null,2)}`) // debug
      
      if (this.props.alertDetails.alert_type === "counts") {
        tableColumns = tableColumns_count
        for (let i = minLimit; i < maxLimit; i++) {
            const level = alertDetails[i]
            if (!this.state.showInvocationZero && level.level_count === 0){
              continue
            }

            // console.log(`Processing level:\n${JSON.stringify(level,null,2)}`) // debug
            const rowStyle = !level.warnLevel ? { backgroundColor: ignore_c } : isAlertLevel(level) ? { backgroundColor: alert_c } : isWarningLevel(level) ? { backgroundColor: warn_c } : { backgroundColor: ok_c } 
            // Add <Link> if link attribute is present in level (defined in config file)
            const level_cell_content = level.link && isValidLink(level.link)
                ? <Link component={RouterLink} color='inherit' to={`/section${level.link}`}>{level.service_name}</Link>
                : level.service_name
              
            const level_count_cell = level.warnLevel ? (
              <TableCell style={rowStyle}><NumberOfStuff maxValue={level.warnLevel}>{level.level_count}</NumberOfStuff>{this.state.editable && <ConfigButton onClick={() => this.handleShowModal(level)}/>}</TableCell>
            ) : (
              <TableCell style={rowStyle}>{level.level_count}{this.state.editable &&<ConfigButton onClick={() => this.handleShowModal(level)}/>}</TableCell>
            )

            alertDetailsPaginated.push(
              
               <TableRow key={i}>
                <TableCell style={rowStyle}>{level_cell_content}</TableCell>
                {level_count_cell}
                <TableCell style={rowStyle}>{level.text}</TableCell>
               </TableRow>
             
            )
          }
      } else if (this.props.alertDetails.type === "processes"){
        tableColumns = tableColumns_process
        const maxLimit = Math.min(alertDetails.length, this.state.page * RESULTS_PER_PAGE)

        // TODO: Mirar la longitud de todos y coger el de mayor longitud, usar normalmente el de conf y si no hay conf hacer eso

        for (let i = minLimit; i < maxLimit; i++) {
          let level = alertDetails[i]
          // console.log(`Processing level:\n${JSON.stringify(level,null,2)}`) // debug
          let rowStyle
          let thresholds
          let threshold_name = []
          // Pasarlo a una función 
          if (! level.hasOwnProperty('thresholds') || level['thresholds'].length === 0){
            rowStyle = { backgroundColor: ignore_c }
          } else {
            thresholds = level.thresholds
            let processThresholdFlag = false
            for (let j = 0; j < thresholds.length; j++){
              if (level.hasOwnProperty(thresholds[j].name)){
                threshold_name.push(thresholds[j].name)
                if (processThresholdFlag){
                  continue
                }
                if (thresholds[j].type === 'max'){
                  if (level[thresholds[j].name] > thresholds[j].alertLevel){
                    rowStyle = { backgroundColor: alert_c }
                    processThresholdFlag = true
                    continue
                  } else if (level[thresholds[j].name] > thresholds[j].warnLevel){
                    rowStyle = { backgroundColor: warn_c }
                    processThresholdFlag = true
                  }
                }
                if (thresholds[j].type === 'min'){
                  if (level[thresholds[j].name] < thresholds[j].alertLevel){
                    rowStyle = { backgroundColor: alert_c }
                    processThresholdFlag = true
                    continue
                  } else if (level[thresholds[j].name] < thresholds[j].warnLevel){
                    rowStyle = { backgroundColor: warn_c }
                    processThresholdFlag = true
                  }
                }
              }
            }
            if (! processThresholdFlag){
              rowStyle = { backgroundColor: ok_c }
            } 
          }
          // Add <Link> if link attribute is present in level (defined in config file)
          const level_cell_content = level.service_name
              ? <Link component={RouterLink} color='inherit' to={`/section/alerts/${this.props.sectionName}/${level.service_name}`}>{level.service_name}</Link>
              : level.service_name
          

          alertDetailsPaginated.push(
            
            <TableRow key={i}>
               {tableColumns.map((object, j) => {
                  if (object.name === 'service_name'){
                    return <TableCell key={object.name + i} style={rowStyle}>{level_cell_content}</TableCell>
                  } else {
                    if (object.type === 'timestamp'){
                      const time = level[object.name]
                      const [, year, month, day, hour, minute, second] = time.match(/^(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})(\d{2})$/).map(Number);
                      const timestamp = new Date(year, month - 1, day, hour, minute, second);
                      // const level_timestamp = timestamp.toLocaleString("en-GB")
                      const level_timestamp = prettyPrintDateTime(timestamp)
                      return <TableCell key={object.name + i}  style={rowStyle}>{level_timestamp}</TableCell>;
                    } else {
                      let level_value = object.type === 'time' ? prettyPrintMs(level[object.name]) : level[object.name]
                      if (threshold_name.includes(object.name)){
                        let threshold = thresholds[threshold_name.indexOf(object.name)]
                        threshold['service_name'] = level.service_name
                        if (threshold.type === 'min'){
                          return <TableCell key={object.name + i} style={rowStyle}><NumberOfStuff type={object.type} minValue={thresholds[threshold_name.indexOf(object.name)].warnLevel}>{level[object.name]}</NumberOfStuff>{this.state.editable && <ConfigButton onClick={() => this.handleShowModal(threshold)}/>}</TableCell>;
                        } else {
                          return <TableCell key={object.name + i} style={rowStyle}><NumberOfStuff type={object.type} maxValue={thresholds[threshold_name.indexOf(object.name)].warnLevel}>{level[object.name]}</NumberOfStuff>{this.state.editable && <ConfigButton onClick={() => this.handleShowModal(threshold)}/>}</TableCell>;
                        }
                      } else {
                        let emptyThreshold = {
                          service_name: level.service_name,
                          name: object.name,
                          warnLevel: 0,
                          alertLevel: 0,
                          type: 'max',
                        }
                        return <TableCell key={object.name + i}  style={rowStyle}>{level_value} {this.state.editable &&<ConfigButton onClick={() => this.handleShowModal(emptyThreshold)}/>}</TableCell>;
                      }
                    }
                  }
                })}
            </TableRow>
          )
        }
        
      }
      else {
          tableColumns = tableColumns_inv_avg
          for (let i = minLimit; i < maxLimit; i++) {
            const level = alertDetails[i]
            if (!this.state.showInvocationZero && level.inv === 0){
              continue
            }
            // console.log(`Processing level:\n${JSON.stringify(level,null,2)}`) // debug
            const rowStyle = isAlertErrored(level) ? { backgroundColor: alert_c } : !isAlertSignificant(level) ? { backgroundColor: ignore_c, opacity: 0.4 } : { backgroundColor: ok_c }
            const level_cell_content = level.link
                ? <Link component={RouterLink} color='inherit' to={`/section${level.link}`}>{level.service_name}</Link>
                : level.service_name
            
            alertDetailsPaginated.push(
              
               <TableRow key={i}>
                <TableCell style={rowStyle}>{level_cell_content}</TableCell>
                <TableCell style={rowStyle}><NumberOfStuff minValue={level.minInv}>{level.inv}</NumberOfStuff>{this.state.editable && <ConfigButton/>}</TableCell>
                <TableCell style={rowStyle}><NumberOfStuff maxValue={level.maxAvg}>{level.avg}</NumberOfStuff>{this.state.editable && <ConfigButton/>}</TableCell>
                <TableCell style={rowStyle}>{level.text}</TableCell>
               </TableRow>
              
            )
          }
      }

      const serviceNames = [
        { label: 'All', value: '' },
        ...removeDuplicates(this.props.alertDetails.alerts.map(d => d.service_name)).map(d => {
          return { value: d, label: d }
        })
      ]

      
      
      content = (
        <Grid container spacing={3} className='AlertSummary'>
          <Grid item xs={12}>
            <TableFilters
              filters={this.state.filter}
              handleChange={this.onFilterChange}
              deferredData={{ service_name: serviceNames }}
            />
            <Table>
              <TableHead>
                <TableRow>
                  {
                    tableColumns.map((col, key) => (
                      <TableColumnTitle
                        key={key}
                        {...col}
                        orderBy={this.state.orderBy}
                        orderDirection={this.state.orderDirection}
                        onSort={this.handleSort} />
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {alertDetailsPaginated}
              </TableBody>
            </Table>
            <Grid container spacing={0} className={this.props.classes.tableTools}>
              <Grid item xs={12} md={4}>
                <Pagination
                  count={calculateNumPages(alertDetails)}
                  page={this.state.page}
                  onChange={this.handlePageChange}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                {alertDetails.length} results
              </Grid>
              <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
                <Button variant='contained' component={RouterLink} to='/section/alerts'>
                  Go back
                </Button>
              </Grid>
              <ThresholdModal
                open={this.state.showModal && Object.keys(this.state.editThreshold).length > 0}
                onClose={() => {
                  this.handleCloseModal()
                }}
                threshold={this.state.editThreshold}
                sectionName={this.props.sectionName}
                loadAlertDetails={this.props.loadAlertDetails}
                deleteable={this.state.editThreshold.warnLevel !== 0 && this.state.editThreshold.alertLevel !== 0}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Section'>
              <SectionTitle
                edit={() => this.handleEditable()}
                showInvocationZero={this.state.showInvocationZero}
                handleShowInvocationsZero={() => this.handleShowInvocationsZero()}
                onRefresh={() => this.props.loadAlertDetails(this.props.sectionName)}
              >
                Alerts
              </SectionTitle>
              <Container maxWidth='xl' className={this.props.classes.cardGrid}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {content}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    alertDetails: state.alerts.alertDetails,
    loading: state.alerts.loading,
    error: state.alerts.error
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadAlertDetails: sectionName => dispatch(actions.loadAlertDetails(sectionName))
  }
}

// Style component.
const SectionStyled = props => {
  const classes = useStyles()
  return <Section classes={classes} {...props} />
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionStyled))
