// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Style.
import { useStyles } from '../../../styles'
import { 
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Grid,
    LinearProgress,
    Button,
} from '@material-ui/core'

// Components
import SectionTitle from '../Section/SectionTitle'
import TestCaseRow from '../../../UI/Table/TestCaseRow'
import AlertError from '../../../UI/Alert/AlertError'

// Redux actions.
import * as actions from '../../../../store/actions'

// Shared.
import { getErrorMessage } from "../../../../shared/functions";
import axios from '../../../../axios'


// =============================================================================
// Component declaration.
// =============================================================================
  
// Stateful component declaration.
class PatDashboard extends React.PureComponent {
  
  constructor(props){
    super(props)
    this.testCaseRowRefs = {}
    this.state = { forceRender: false }
  }

  toggleRefs = (id, testCase) => {
    if (!this.testCaseRowRefs[id]){
      this.testCaseRowRefs[id] = {
        open: true,
        openModal: false
      }
    } else {
      this.testCaseRowRefs[id][testCase] = !this.testCaseRowRefs[id][testCase]      
    }
    this.setState({ forceRender: !this.state.forceRender })
    console.log(this.testCaseRowRefs)
  }

  componentDidMount () {
    this.props.loadDashboard()
    this.interval = setInterval(() => {
      this.props.loadDashboardNoReload()
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  resetDashboard() {
    const api_path = '/api/dashboard/reset'
    axios.post(api_path).then(
      this.props.loadDashboard()
    )
  }

  render () {

    const headers = [
      'Name',
      'Status',
      'Start time',
      'End time',
      'Progress',
      'Nº Endpoints',
      'Total duration expected'
    ]
    let content = null
    if (this.props.loading) {
      content = <LinearProgress />
    } else if (this.props.error) {
      content = <AlertError>{getErrorMessage(this.props.error)}</AlertError>
    } else if (this.props.dashboard && this.props.dashboard.running && this.props.dashboard.done) {
      content = (
        <Table>
          <TableHead style={{background:'#1976d240'}}>
            <TableRow>
              <TableCell/>
              {headers.map((header, index) => (
                <TableCell style={{ fontWeight: "bold" }} align={index !== 0 ? "right" : ''}>
                  <b>{header}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {(Object.entries(this.props.dashboard.running).length === 0 && Object.entries(this.props.dashboard.done).length === 0) ? (
            <TableBody>
              <TableRow style={{background:'#ADD8E640'}}>
                <TableCell colSpan={headers.length + 1} align="center">
                Empty dashboard. Waiting...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableBody>
                {Object.entries(this.props.dashboard.running).length > 0 && Object.entries(this.props.dashboard.running).map(([name, row]) => (
                  <TestCaseRow name={name} row={row} refs={this.testCaseRowRefs} toggleRefs={this.toggleRefs}/>
                ))}
              </TableBody>
              <TableBody>
                {Object.entries(this.props.dashboard.done).length > 0 && Object.entries(this.props.dashboard.done).map(([name, row]) => (
                  <TestCaseRow name={name} row={row} refs={this.testCaseRowRefs} toggleRefs={this.toggleRefs}/>
                ))}
              </TableBody>
            </>
          )
          }

        </Table>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div>
              <SectionTitle
                onRefresh={() => 
                this.props.loadDashboard()        
               }
              >
                Patloader Dashboard
              </SectionTitle>
              <TableContainer component={Paper}>
                {content}
              </TableContainer>
              <Grid item style={{ textAlign: 'right', paddingTop:'1em' }}>
                <Button onClick={() => this.resetDashboard()} variant='contained'>
                  Reset dashboard
                </Button>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    loading: state.dashboard.loading,
    dashboard: state.dashboard.dashboard
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadDashboard: () => dispatch(actions.loadDashboard()),
    loadDashboardNoReload: () => dispatch(actions.loadDashboardNoReload())
  }
}

// Style component.
const PatDashboardStyled = props => {
  const classes = useStyles()
  return <PatDashboard classes={classes} {...props} />
}


// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatDashboardStyled))
