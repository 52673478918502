// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const AlertInfo = props => {
  const classes = useStyles()
  return (
    <Alert severity='warning' className={classes.alert}>
      <AlertTitle>Message from the system</AlertTitle>
      {props.children}
    </Alert>
  )
}

// Export.
export default AlertInfo
