// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
    Grid,
    Paper,
    Container,
    LinearProgress,
    // Select,
    // MenuItem,
    // InputLabel,
    // FormControl,
    // Button
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Components.
import Chart from '../../../UI/Chart/Chart'
import ChartFilter from '../../../UI/Chart/ChartFilter'
import AlertError from '../../../UI/Alert/AlertError'
import SectionTitle from '../Section/SectionTitle'

// Style.
import { useStyles } from '../../../styles'
import './AlertsSection.css'


// Redux actions.
import * as actions from '../../../../store/actions'

// Shared.
import { beautifySectionName, getErrorMessage, transformStringToDate } from '../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Section extends Component {

    state = {
      limit: null,
      date: null,
      maxDate: null,
      timeScale: true,
      groupData: 'none'
    }

    componentDidMount() {
      const query = new URLSearchParams(this.props.location.search);
      let date = ''
      let parsedMaxDate = ''
      let maxDate = ''
      if (query.get('date')){
        date = query.get('date')
      }
      let limit
      if (query.get('limit') && !isNaN(parseInt(query.get('limit')))){
        limit = parseInt(query.get('limit'))
      } else {
        limit = 100
      }
      if (query.get('maxDate')){
        maxDate = new Date(query.get('maxDate'))
        console.log(maxDate)
        maxDate.setHours(23)
        maxDate.setMinutes(59)
        parsedMaxDate = Date.parse(maxDate)
      }
      this.setState({limit:limit, date:date, maxDate:maxDate})
      this.props.loadAlertProcess(this.props.sectionName, this.props.process, limit, date, parsedMaxDate)
    }

    handleSearch = (limit, date, maxDate) => {
      const query = new URLSearchParams(this.props.location.search);
      query.set('limit', limit)
      query.set('date', date)
      query.set('maxDate', maxDate)
      this.setState({limit:limit, date:date, maxDate:maxDate})
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: query.toString()
      })
      let parsedMaxDate
      if (!maxDate){
        maxDate = new Date()
        parsedMaxDate = Date.parse(maxDate)
      } else {
        maxDate = new Date(maxDate)
        maxDate.setHours(23)
        maxDate.setMinutes(59)
        parsedMaxDate = Date.parse(maxDate)
      }
      this.props.loadAlertProcess(this.props.sectionName, this.props.process, limit, date, parsedMaxDate)
    }

    handleGroupData = (groupData) => {
      this.setState({groupData: groupData})
    }

    handleScale = (timeScale) => {
      this.setState({timeScale: timeScale})
    }
    
    render () {
        let content = null
        if (this.props.loading) {
          content = <LinearProgress />
        } else if (this.props.error) {
          content = <AlertError>{getErrorMessage(this.props.error)}</AlertError>
        } else if (this.props.alertProcess) {
            let last_elements = this.props.alertProcess.process.last_elements
            let dataChart = []
            for (let element in last_elements){
                let process = last_elements[element]
                let processData = {
                    'label': transformStringToDate(process.start_time),
                    // 'label': process.name,
                    'processed': process.processed,
                    'executions': process.executions,
                    // 'load_time_ms': process.load_time_ms,
                    'failed': process.failed,
                    // 'loop_time_ms': process.loop_time_ms,
                    'skipped': process.skipped,
                }
                dataChart.push(processData)
            }
            dataChart.sort((a,b) => a.label - b.label)
            content = (
                  <>
                    <ChartFilter
                      limit={this.state.limit}
                      date={this.state.date}
                      maxDate={this.state.maxDate}
                      groupData={this.state.groupData}
                      handleSearch={this.handleSearch}
                      handleScale={this.handleScale}
                      handleGroupData={this.handleGroupData}
                    />
                    <Chart
                      type="line"
                      data={dataChart}
                      time
                      timeScale={this.state.timeScale}
                      groupData={this.state.groupData}
                    />
                  </>
            )
        }

        return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={this.props.classes.paper}>
                  <div className='Section'>
                    <SectionTitle>
                      {beautifySectionName(this.props.process)}
                    </SectionTitle>
                    <Container maxWidth='xl' className={this.props.classes.cardGrid}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {content}
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </Paper>
              </Grid>
            </Grid>
        )
    }
}


// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
    return {
        alertProcess: state.alerts.alertProcess,
        loading: state.alerts.loading,
        error: state.alerts.error
    }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
    return {
        loadAlertProcess: (sectionName, process, limit_process, date, maxDate) => dispatch(actions.loadAlertProcess(sectionName, process, limit_process, date, maxDate))
    }
}

// Style component.
const SectionStyled = props => {
    const classes = useStyles()
    return <Section classes={classes} {...props} />
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionStyled))

