// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Modal, Box, Typography, TextField, Button, MenuItem, Dialog, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import axios from '../../../axios'

// =============================================================================
// Component declaration.
// =============================================================================

class ThresholdModal extends Component {

    state={
        threshold: {},
        open: false
    }

    componentDidMount() {
        this.setState({threshold: {...this.props.threshold}})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.threshold !== this.props.threshold){
            this.setState({
                threshold: {...this.props.threshold}
            })
        }
    }

    onChangeHandle = (value, propertyName) => {
        let updatedThreshold = this.state.threshold
        updatedThreshold[propertyName] = value
        this.setState({threshold: updatedThreshold})
    }

    saveThreshold() {
        const api_path = `/api/thresholds/${this.props.sectionName}`    
        let threshold = this.state.threshold
        axios.post(api_path, {
            threshold
        })
        .then(this.props.onClose)
        .catch(error => {
            this.props.onClose()
        })
    }

    deleteThreshold = () => {
        const api_path = `/api/thresholds/${this.props.sectionName}`    
        let threshold = this.state.threshold
        axios.delete(api_path, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                threshold: threshold
            }
        })
        .then(() => {
            this.handleCloseDialog()
            this.props.loadAlertDetails(this.props.sectionName)
        })
        .catch(error => {
            this.handleCloseDialog()
        })
    }

    handleOpenDialog = () => {
        this.setState({open: true})
    }

    handleCloseDialog = () => {
        this.setState({open: false})
        this.props.onClose()
    }

    render () {
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <Box
                    sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography variant='body2'>
                    Editing level: <strong>{this.state.threshold.service_name}</strong>
                    { this.state.threshold.name && <Typography variant='body2'>Field <strong>{this.state.threshold.name}</strong></Typography>}
                    </Typography>
                    <TextField
                    fullWidth
                    label="Warn level"
                    type="text"
                    value={
                        this.state.threshold.warnLevel
                        ? this.state.threshold.warnLevel
                        : 0
                    }
                    onChange={(event) =>
                        this.onChangeHandle(parseInt(event.target.value), 'warnLevel')
                    }
                    />
                    <TextField
                    fullWidth
                    label="Alert level"
                    type="text"
                    value={
                        this.state.threshold.alertLevel
                        ? this.state.threshold.alertLevel
                        : 0
                    }
                    onChange={(event) =>
                        this.onChangeHandle(parseInt(event.target.value), 'alertLevel')
                    }
                    />
                    {(this.state.threshold.type === 'max' || this.state.threshold.type ==='min') && 
                    <TextField
                        fullWidth
                        select
                        label="Type"
                        value={this.state.threshold.type}
                        onChange={(event) =>
                            this.onChangeHandle(event.target.value, 'type')
                        }
                    >
                        <MenuItem value="max">Max</MenuItem>
                        <MenuItem value="min">Min</MenuItem>
                    </TextField>
                    }
                    <Box display="flex" justifyContent="center" paddingTop="1em">
                    <Button onClick={() => {
                        this.saveThreshold()
                        this.props.loadAlertDetails(this.props.sectionName)
                    }} variant="contained" color="primary">
                        SAVE
                    </Button>
                    { this.props.deleteable && (
                        <>
                            <div style={{padding: '1em'}}></div>
                            <Button style={{ backgroundColor: '#ff3333'}} onClick={this.handleOpenDialog}>DELETE</Button>
                            <Dialog
                                open={this.state.open}>
                                <DialogTitle>
                                    {`Removing Alert`}
                                </DialogTitle>
                                <DialogContent>
                                {this.state.threshold.name !== 'undefined' ? (
                                `Are you sure you want to delete the alert ${this.state.threshold.service_name}?` 
                                ) : (
                                `Are you sure you want to delete the field ${this.state.threshold.name} in the alert ${this.state.threshold.service_name}?`
                                )}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.deleteThreshold}>YES</Button>
                                    <Button onClick={this.handleCloseDialog}>NO</Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    )}
                    </Box>
                </Box>
            </Modal>
        )
    }
}

export default ThresholdModal