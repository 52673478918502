// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Container } from '@material-ui/core'

// Style.
import './Error404.css'

// Components.
import ErrorBox from './ErrorBox/ErrorBox'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ErrorPage = () => {
  return (
    <Container component='main' maxWidth='xs' className='ErrorPage'>
      <ErrorBox status={404} description='Página no encontrada' />
    </Container>
  )
}

// Export.
export default ErrorPage
