// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Container
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Components.
import Title from '../../../UI/Brand/Title'
import SectionCard from './SectionCard'

// Style.
import { useStyles } from '../../../styles'
import * as actions from '../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Home extends Component {
  componentDidMount () {
    this.props.resetSection()
  }

  getPreviewImage (chartType, idx) {
    return `/index/${chartType}${(idx % 2) + 1}.png`
  }

  render () {
    const nCharts = {}
    
    return (
    
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Homepage'>
              <Title>List of available sections</Title>
              <Container maxWidth='xl' className={this.props.classes.cardGrid}>
                <Grid container spacing={2}>
                  {
                    Object.keys(this.props.sections).map((sectionKey, key) => {
                      const section = this.props.sections[sectionKey]
                      if (section.dataFile) {
                        if (!nCharts[section.chartType]) nCharts[section.chartType] = 0
                        nCharts[section.chartType]++
                        return (
                          <SectionCard
                            sectionKey={sectionKey}
                            link={`/section/${sectionKey}`}
                            section={section}
                            idx={nCharts[section.chartType]}
                            key={key}
                          />
                        )
                      }
                      return Object.keys(section).map((childSectionKey, key) => {
                        const childSection = section[childSectionKey]
                        if (!nCharts[childSection.chartType]) nCharts[childSection.chartType] = 0
                        nCharts[childSection.chartType]++
                        return (
                          <SectionCard
                            sectionKey={childSectionKey}
                            link={`/section/${sectionKey}/${childSectionKey}`}
                            section={childSection}
                            idx={nCharts[childSection.chartType]}
                            key={key}
                          />
                        )
                      })
                    })
                  }
                </Grid>
              </Container>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    sections: state.sections.sections
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    resetSection: () => dispatch(actions.resetSection())
  }
}

// Style component.
const HomeStyled = props => {
  const classes = useStyles()
  return <Home classes={classes} {...props} />
}


// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeStyled))
