// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Box,
  CssBaseline
} from '@material-ui/core'

// Style.
import './ErrorBox.css'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ErrorBox = props => {
  return (
    <div className='ErrorBoxContainer'>
      <div className='ErrorBox'>
        <CssBaseline />
        <Box className='ErrorPageCode'>
          {props.status}
        </Box>
        <Box className='ErrorPageDescription'>
          {props.description}
        </Box>
      </div>
    </div>
  )
}

// Export.
export default withRouter(ErrorBox)
