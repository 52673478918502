// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
    CircularProgress,
    Box,
    Typography
} from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const CircularProgressWithLabel = props => (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" value={props.current/props.total * 100} />
        <Box
        sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
        >
            <Typography
                variant="caption"
                component="div"
                sx={{ color: 'text.secondary' }}
            >
                {`${props.current}/${props.total}`}
            </Typography>
        </Box>
    </Box>
)

// Export.
export default CircularProgressWithLabel