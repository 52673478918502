// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'

// Shared.
import { NUMERIC_VALUE_NAMES, RESULTS_PER_PAGE } from './constants'

// =============================================================================
// Functions.
// =============================================================================

// Set the locale of the dates.
moment.locale('es')

// Update an object with new one.
export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps
  }
}

// Get an error message with the API standarized syntax.
export const getErrorMessage = (error) => {
  if (error.response && error.response.data) {
    error = error.response.data
  }
  const r = { message: error.message, details: [] }
  if (error.details) {
    if (typeof error.details === 'object') {
      if (error.details.message) {
        r.details.push(error.details.message)
      } else {
        for (const key in error.details) {
          if (Object.prototype.hasOwnProperty.call(error.details, key)) {
            r.details.push(error.details[key])
          }
        }
      }
    } else {
      r.details.push(error.details)
    }
  }
  return r
}

// Get a key - value object as an options array for a SELECT element.
export const getOptionsArray = (obj, allowNull = true) => {
  const r = []
  if (allowNull) {
    r.push({ value: '', label: '-' })
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      r.push({ value: key, label: obj[key] })
    }
  }
  return r
}

// Transform string YYYYMMDD_HHMMss to Date
export const transformStringToDate = dateString => {

  const year = parseInt(dateString.substring(0, 4))
  const month = parseInt(dateString.substring(4, 6)) - 1
  const day = parseInt(dateString.substring(6, 8))
  const hour = parseInt(dateString.substring(9, 11))
  const minute = parseInt(dateString.substring(11, 13))
  const second = parseInt(dateString.substring(13, 15))

  return new Date(year, month, day, hour, minute, second)
}


// Pretty-print a date.
export const prettyPrintDateTime = date => {
  if (!moment(date).isValid()){
    date = transformStringToDate(date)
  }
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}
// Format a date for the date time picker.
export const formatDateTimePickerDate = date => moment(date).format('YYYY-MM-DDTHH:mm')

// Pretty-print a date.
export const prettyPrintDate = date => moment(date).format('YYYY-MM-DD')

// Print human readable datetime.
export const printReadableDateTime = date => moment(date, "YYYY-MM-DD hh:mm:ss A").format('LLL')

// Pretty print a short date.
export const prettyPrintShortDate = date => moment(date).format('D MMM')

// Pretty print date with houts
export const prettyPrintDateHours = date => moment(date).format('DD/MM HH:mm')

// Pretty print time from miliseconds to minutes, seconds and miliseconds
export const prettyPrintMs = ms => {
  const hours = Math.floor(ms / 3600000)
  const minutes = Math.floor((ms - hours * 3600000) / 60000)
  const seconds = Math.floor((ms - hours * 3600000 - minutes * 60000) / 1000)
  const miliseconds = ms - hours * 3600000 - minutes * 60000 - seconds * 1000
  if  (hours > 0){
    return `${hours} h ${minutes} min`
  } else if (minutes > 0){
    return `${minutes} min ${seconds} s`
  } else if (seconds > 0){
    return `${seconds} s`
  } else {
    return `${miliseconds} ms`
  }
}


// Build a URL with parameters.
export const buildUrl = (url, params = {}) => {
  const currentQueryParts = url.split('?')
  if (currentQueryParts.length > 1) {
    const currentQueryString = currentQueryParts[1]
      .split('&')
      .map(e => {
        const parts = e.split('=')
        return { name: parts[0], value: parts[1] }
      })
    const currentParams = {}
    for (let i = 0; i < currentQueryString.length; i++) {
      currentParams[currentQueryString[i].name] = currentQueryString[i].value
    }
    url = currentQueryParts[0]
    params = { ...currentParams, ...params }
  }
  const queryString = _.keys(params).map(k => `${k}=${params[k]}`).join('&')
  return `${url}?${queryString}`
}

// Uppercase first letter.
export const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1)

// Beautify the name of a section.
export const beautifySectionName = str => ucFirst(str).replace(/_/g, ' ').replace(/[\s]+/, ' ')

// Returns true if a count is above warning level
export const isAlertLevel = level => (level.level_count > level.alertLevel)

// Returns true if a count is above alertLevel
export const isWarningLevel = level => (level.level_count > level.warnLevel)

// Returns true if an alert is errored.
export const isAlertErrored = alert => (alert.avg > alert.maxAvg && alert.inv >= alert.minInv)

// Returns true if an alert is significant.
export const isAlertSignificant = alert => (alert.inv >= alert.minInv)

export const isValidLink = link => {
  return link.startsWith('/') && link.split('/').length > 1
}

// Replace special characters in a string regular expression.
export const includeSpecialCharsRegExp = str => {
  return str
    .replace(/a/g, '[aáàäâ]{1}')
    .replace(/e/g, '[eéèëê]{1}')
    .replace(/i/g, '[iíìïî]{1}')
    .replace(/o/g, '[oóòöô]{1}')
    .replace(/u/g, '[uúùüû]{1}')
}

// Convert a string to a specific type.
export const convertType = (str, type, key) => {
  switch (type) {
    case 'select': /* fall through */
    case 'autocomplete':
      if (/_id$/.test(key) || /Id$/.test(key) || NUMERIC_VALUE_NAMES.indexOf(key) !== -1) return +str
      return str
    case 'number':
      return +str
    case 'date':
      return new Date(str)
    default:
      return str
  }
}

// Apply a filter to an array of objects.
export const applyFilter = (objArray, filterObj, orderBy = null, orderDirection = 'asc') => {
  let filteredObjArray = objArray
  for (const key in filterObj) {
    if (Object.prototype.hasOwnProperty.call(filterObj, key)) {
      // Return, if the value of the filter is null.
      if (!filterObj[key].value) continue
      // Convert the value to the specified type.
      const value = convertType(filterObj[key].value, filterObj[key].type, key)
      const stringParts = `${value}`.replace(/[\s]+/g, ' ').split(' ')
      const regularExpressions = stringParts.map(s => new RegExp(includeSpecialCharsRegExp(s), 'gi'))
      // Perform actions depending on the filter operand.
      switch (filterObj[key].operand) {
        case 'equals':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) === value)
          break
        case 'gte':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) >= value)
          break
        case 'lte':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) <= value)
          break
        case 'lt':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) < value)
          break
        case 'gt':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) > value)
          break
        case 'match': /* string search */
          filteredObjArray = []
          for (let i = 0; i < objArray.length; i++) {
            let allPartsMatch = true
            for (let j = 0; j < regularExpressions.length; j++) {
              if (!regularExpressions[j].test(objArray[i][filterObj[key].key])) {
                allPartsMatch = false
                break
              }
            }
            if (allPartsMatch) filteredObjArray.push(objArray[i])
          }
          break
        default:
      }
    }
  }
  // Apply the order.
  if (orderBy) {
    filteredObjArray = _.orderBy(filteredObjArray, orderBy, orderDirection)
  }
  return filteredObjArray
}

// Remove duplicates from an array of objects.
export const removeDuplicates = arr => {
  const uniqueArray = []
  for (let i = 0; i < arr.length; i++) {
    const newElement = arr[i]
    let alreadyPresent = false
    for (let j = 0; j < uniqueArray.length; j++) {
      const alreadyPresentElement = uniqueArray[j]
      let allPropsEqual = true
      for (const key in alreadyPresentElement) {
        if (Object.prototype.hasOwnProperty.call(alreadyPresentElement, key)) {
          allPropsEqual &= newElement[key] === alreadyPresentElement[key]
        }
      }
      if (allPropsEqual) {
        alreadyPresent = true
        break
      }
    }
    if (!alreadyPresent) uniqueArray.push(newElement)
  }
  return uniqueArray
}

// Calculate number of pages.
export const calculateNumPages = (results, rpp = RESULTS_PER_PAGE) => Math.ceil(results.length / rpp)
