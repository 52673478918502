// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React, Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

// CSS.
import './App.css'

// Components.
import Brp from './BRP/BRP'
import ErrorPage from './ErrorPage/Error404/Error404'
import OfflinePage from './ErrorPage/Offline/Offline'

// Redux actions.
import * as actions from '../store/actions/index'

// =============================================================================
// Definition of routes.
// =============================================================================

// Routes.
const routes = [
  {
    path: '/',
    exact: true,
    render: () => <Brp section='home' />
  },
  {
    path: '/section/alerts',
    exact: true,
    render: () => <Brp section='alerts' />
  },
  {
    path: '/section/alerts/:sectionName/:process',
    exact: false,
    render: () => <Brp section='alertProcess' />
  },
  {
    path: '/section/alerts/:sectionName',
    exact: false,
    render: () => <Brp section='alertDetails' />
  },
  {
    path: '/alerts/:sectionName',
    exact: true,
    render: ({match}) => <Redirect to={`/section/alerts/${match.params.sectionName}`} />
  },
  {
    path: '/section/:sectionParent/:sectionName',
    exact: false,
    render: () => <Brp section='sectionWithParent' />
  },
  {
    path: '/section/:sectionName',
    exact: false,
    render: () => <Brp section='sectionWithoutParent' />
  },
  {
    path: '/admin',
    exact: true,
    render: () => <Brp section='admin' />
  },
  {
    path: '/dashboard',
    exact: true,
    render: () => <Brp section='dashboard' />
  }
]

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class App extends Component {
  playSound = (url) => {
    const audio = new Audio(url);
    audio.play();
  }
  componentDidMount () {
    console.log (`App componentDidMount: loadSections(), loadAlerts()`)
    this.props.loadSections() // GET to /api/sections endpoint
    this.props.loadAlerts() // GET to /api/alerts endpoint
    this.props.loadUnreadEvents()
    // Connect to WebSocket server
    this.socket = new WebSocket(`wss://${window.location.hostname}`)

    this.socket.onopen = () => {
      console.log(`Connected to WebSocket server on wss://${window.location.hostname}:${window.location.port}`);
    };

    this.socket.onmessage = (event) => {
      console.log('Message from server: ', event.data);
      let data = JSON.parse(event.data)
      let type = data.type
      if (type === 'event'){
        const notification = new Notification("Sbmreports notification", {
          body: data.message,
        })
        this.props.loadUnreadEvents()
        this.playSound('/notification.wav')
        notification.onclick = (event) => {
          // event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.location.href='/'
        }
      } else {
        const notification = new Notification("Sbmreports alerts", {
            body: data.message,
            // icon: "../../public/favicon.ico"
          })
        this.playSound('/lightsaber.mp3')
        notification.onclick = (event) => {
          // event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.location.href='/section/alerts'
        }
      }     
    }

    this.socket.onclose = () => {
      console.log(`Disconnected from WebSocket server`);
    };

    // Error management
    this.socket.onerror = (error) => {
      console.log('WebSocket error: ', error);
    };
  }

  componentWillUnmount() {
    // Clean connection
    if (this.socket) {
      this.socket.close();
    }
  }

  render () {
    
    // Loading state.
    if (this.props.loading) {
      return <div className='GlobalLoader'><CircularProgress size={60} color='inherit' /></div>
    }

    // Check offline mode.
    if (this.props.error) {
      return <OfflinePage />
    }

    return (
      <div className='App'>
        <Router>
          <Switch>
            {
              routes.map((route, key) => {
                return (
                  <Route
                    key={key}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    render={route.render}
                  />
                )
              })
            }
            <Route render={() => <ErrorPage status='404' />} />
          </Switch>
        </Router>
      </div>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    loading: state.sections.loading,
    error: state.sections.error
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSections: () => dispatch(actions.loadSections()),
    loadAlerts: () => dispatch(actions.loadAlerts()),
    loadUnreadEvents: () => dispatch(actions.loadUnreadEvents())
  }
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(App)
