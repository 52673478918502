// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  section: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading section.
const loadAdminConfigStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    adminConfig: null
  })
}

// Load a section successfully.
const loadAdminConfigSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    adminConfig: action.adminConfig
  })
}

// Load section, with error.
const loadAdminConfigFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    adminConfig: null
  })
}

// Start loading section.
const updateAdminConfigStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    adminConfig: null
  })
}

// Load a section successfully.
const updateAdminConfigSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    adminConfig: action.adminConfig
  })
}

// Load section, with error.
const updateAdminConfigFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    adminConfig: null
  })
}


// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_ADMIN_CONFIG_START:
      return loadAdminConfigStart(state, action)
    case actions.LOAD_ADMIN_CONFIG_SUCCESS:
      return loadAdminConfigSuccess(state, action)
    case actions.LOAD_ADMIN_CONFIG_FAILED:
      return loadAdminConfigFailed(state, action)
    case actions.UPDATE_ADMIN_CONFIG_START:
      return updateAdminConfigStart(state, action)
    case actions.UPDATE_ADMIN_CONFIG_SUCCESS:
      return updateAdminConfigSuccess(state, action)
    case actions.UPDATE_ADMIN_CONFIG_FAILED:
      return updateAdminConfigFailed(state, action)
    default:
      return state
  }
}

export default reducer
