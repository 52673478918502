// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React  from 'react'
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  Link
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

// Style.
import { useStyles } from '../../../styles'
import './SectionCard.css'

// =============================================================================
// Component declaration.
// =============================================================================

const getPreviewImage = (chartType, idx) => {
  return `/index/${chartType}${(idx % 2) + 1}.png`
}

// Stateless component declaration.
const SectionCard = props => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Link component={RouterLink} to={props.link}>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={props.section.type === 'section' ? getPreviewImage(props.section.chartType, props.idx) : getPreviewImage(props.section.type, props.idx)}
              title={props.section.dataFile}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {props.section.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.section.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  )
}

// Export.
export default SectionCard
