// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Container,
  LinearProgress
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Components.
import Chart from '../../../UI/Chart/Chart'
import AlertError from '../../../UI/Alert/AlertError'
import SectionTitle from './SectionTitle'

// Style.
import { useStyles } from '../../../styles'
import './Section.css'

// Redux actions.
import * as actions from '../../../../store/actions'

// Shared.
import { beautifySectionName, getErrorMessage } from '../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Section extends Component {
  
  state = {
    date: null
  }


  componentDidMount () {
    const query = new URLSearchParams(this.props.location.search);
    let name = query.get('name')
    this.props.loadSection(this.props.name, this.props.parent, name)
    this.setState({date: query.get('date')})
  }

  componentDidUpdate (prevProps) {
    if(prevProps.section !== this.props.section && this.props.section && this.props.section.service){
      const query = new URLSearchParams(this.props.location.search);
      query.set('name', this.props.section.service)
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: query.toString()
      })
    }
  }

  handleChangeSelector = serviceName => {
    const query = new URLSearchParams(this.props.location.search);
    query.set('name', serviceName)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: query.toString()
    })
    this.props.loadSection(this.props.name, this.props.parent, serviceName)
  }

  render () {
    let content = null
    if (this.props.loading) {
      content = <LinearProgress />
    } else if (this.props.error) {
      content = <AlertError>{getErrorMessage(this.props.error)}</AlertError>
    } else if (this.props.section) {
      content = (
        <div className='SectionData'>
          {this.props.section.description ? <div className='SectionDescription'>{this.props.section.description}</div> : null}
          <Chart
            type={this.props.section.chartType}
            data={this.props.section.data}
            dataFile={this.props.section.timestampFormat ? this.props.section.dataFiles.find(f => !!f.default).file : this.props.section.dataFile}
            dataFiles={this.props.section.dataFiles}
            timestamped={this.props.section.timestampFormat ? true : false}
            stacked={this.props.section.stacked}
            left_dataset_label={this.props.section.left_dataset_label}
            right_dataset_label={this.props.section.right_dataset_label}
            onRefresh={() => this.props.loadSection(this.props.name, this.props.parent)}
            selector={this.props.section.services}
            selection={this.props.section.service}
            handleChangeSelector={(serviceName) => this.handleChangeSelector(serviceName)}
            date={this.state.date}
            location={this.props.location}
            history={this.props.history}
          />
        </div>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Section'>
              <SectionTitle
                onRefresh={() => this.props.loadSection(this.props.name, this.props.parent)}
              >
                {this.props.section ? this.props.section.title : beautifySectionName(this.props.name)}
              </SectionTitle>
              <Container maxWidth='xl' className={this.props.classes.cardGrid}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {content}
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    sections: state.sections.sections,
    section: state.section.section,
    loading: state.section.loading,
    error: state.section.error
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSection: (sectionName, sectionParent, serviceName = undefined) => dispatch(actions.loadSection(sectionName, sectionParent, serviceName))
  }
}

// Style component.
const SectionStyled = props => {
  const classes = useStyles()
  return <Section classes={classes} {...props} />
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionStyled))
