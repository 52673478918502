// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const AlertError = props => {
  const classes = useStyles()
  return (
    <Alert severity='error' className={classes.alert}>
      <AlertTitle>An error was found</AlertTitle>
      {props.children.message}
      <ul>
        {props.children.details.map((detail, key) => <li key={key}>{detail}</li>)}
      </ul>
    </Alert>
  )
}

// Export.
export default AlertError
