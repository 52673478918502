// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import { CssBaseline } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

// Components.
import TopMenu from './Menus/TopMenu'
import Content from './Content/Content'

// Style.
import { useStyles } from '../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const BRP = props => {
    
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopMenu />
      <Content
        section={props.section}
        reqParams={props.match && props.match.params ? props.match.params : {}}
      />
    </div>
  )
}

// Export.
export default withRouter(BRP)
